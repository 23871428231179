import { ReactNode } from "react";

import Sidebar from "components/navigation/Sidebar";
import Page from "components/ui/page/Page";

interface ICategoryProps {
  title: string;
  subtitle?: string;
  layoutClasses: string;
  productList: ReactNode;
}

export default function Category(props: ICategoryProps) {
  const { title, subtitle, layoutClasses, productList } = props;

  return (
    <Page title={title} subtitle={subtitle}>
      <div className="content-body sidebar-body">
        <Sidebar />

        <div className={`product-list ${layoutClasses}`}>{productList}</div>
      </div>
    </Page>
  );
}
