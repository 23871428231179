import { IBlockedChoiceDto, IContextDto, IModifierDto, IProductDto } from "@crunchit/types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import chevronSrc from "assets/img/icons/chevron.svg";
import SimpleTotal from "components/basket/totals/SimpleTotal";
import useActiveModifier from "./useActiveModifier";
import useModifiers from "./useModifiers";
import QuantityModifier from "./QuantityModifier";
import Modifier from "./Modifier";

import { useAppSelector } from "store/app";
import useTracking from "tracking/useTracking";

import "./Modifiers.scss";

interface IModifierListProps {
  product: IProductDto;
  context: IContextDto;
  blockedChoices: IBlockedChoiceDto[];
  dismiss: () => void;
}

export default function Modifiers(props: IModifierListProps) {
  // Wrapper for all productModifiers, to keep track of choices, max and min, etc.
  const { product, context, blockedChoices } = props;
  const { dismiss } = props;
  const [quantity, setQuantity] = useState(1);

  const quantityModifierBlacklist = useMemo(() => ["dips", "salat", "sauce", "bagt kartoffel"], []);

  const { t } = useTranslation();

  const { trackMenuEvent } = useTracking();

  const { activeModifierIndex, updateActiveModifierIndex, collapseModifier, expandModifier } = useActiveModifier();
  const { getModifiers, getProductModifier, selectedChoices, updateChoiceCount, minChoicesReached, includedChoicesReached, getTotalPriceForModifiers, finalize } = useModifiers(product, context);
  const { appSettings } = useAppSelector();
  const navigate = useNavigate();

  const modifiers = useMemo(() => getModifiers(blockedChoices), [getModifiers, blockedChoices]);
  const totalPrice = useMemo(() => getTotalPriceForModifiers(modifiers, quantity), [getTotalPriceForModifiers, modifiers, quantity]);

  const showQuantityModifier = useMemo(() => {
    const productName = product.internalName.trim().toLowerCase();
    return !quantityModifierBlacklist.includes(productName) && modifiers.length > 0;
  }, [modifiers, product.internalName, quantityModifierBlacklist]);

  const handleQuantityCountUpdate = (updatedCount: number) => {
    setQuantity(updatedCount);
  };

  const handleChoiceCountUpdate = (choiceId: number, modifier: IModifierDto, newCount: number) => {
    const continueToNextModifier = updateChoiceCount(choiceId, modifier, newCount);
    if (continueToNextModifier) {
      updateActiveModifierIndex(activeModifierIndex + 1, modifier.id);
    }
  };

  const handleFinalizeClick = (continueToCheckout?: boolean) => {
    finalize(quantity).then(() => {
      if (continueToCheckout) {
        const path = appSettings.precheckoutCategory && !window.location.href.includes("precheckout") ? "/precheckout" : "/checkout";
        navigate(path);
      } else {
        dismiss();
      }
    });
  };

  useEffect(() => {
    trackMenuEvent("MODIFIER_EXPANDED", { products: [product] });
  }, [product, trackMenuEvent]);

  const allIncludedChoicesReached = useMemo(() => modifiers.every((modifier) => includedChoicesReached(modifier, quantity)), [modifiers, quantity, includedChoicesReached]);
  const allMinChoicesReached = useMemo(() => modifiers.every((modifier) => minChoicesReached(modifier, quantity)), [modifiers, quantity, minChoicesReached]);

  const showWarning = useMemo(() => !allIncludedChoicesReached || !allMinChoicesReached, [allIncludedChoicesReached, allMinChoicesReached]);
  const disableFinalize = useMemo(() => !allMinChoicesReached, [allMinChoicesReached]);

  return (
    <section className="product-modifier-container">
      {showQuantityModifier && <QuantityModifier productName={product.name} price={product.price} quantity={quantity} handleChange={handleQuantityCountUpdate} />}

      {modifiers.map(function (modifier, index) {
        const productModifier = getProductModifier(modifier.id);
        return !productModifier ? null : <Modifier key={index} modifierIndex={index + (showQuantityModifier ? 2 : 1)} modifier={modifier} productModifier={productModifier} choices={context.choices} productQuantity={quantity} selectedChoices={selectedChoices[modifier.id]} handleChoiceCountUpdate={handleChoiceCountUpdate} dismiss={collapseModifier} expand={() => expandModifier(index)} />;
      })}

      <div className="product-modifier-footer">
        <SimpleTotal total={totalPrice} />

        {showWarning && <p className="missing-choices-warning">{t("errors:Products.ModifierChoicesMissing")}</p>}

        <div className="product-cta-buttons">
          <button className="button button-colored red" onClick={() => handleFinalizeClick()} disabled={disableFinalize}>
            {t("products:AddToCart")}
          </button>

          <button className="button button-colored green checkout-button" onClick={() => handleFinalizeClick(true)} disabled={disableFinalize}>
            {t("cart:GoToCheckout")}
          </button>
        </div>

        <div className="dismiss-container">
          <button className="dismiss-button" onClick={dismiss} style={{ backgroundImage: `url(${chevronSrc})` }}></button>
        </div>
      </div>
    </section>
  );
}
