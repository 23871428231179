import { IContextDto, ISessionChoice, ISessionItem, IProductModifierDto } from "@crunchit/types";

export const getModifierIds = (choices: ISessionChoice[]) => {
  return choices.reduce((accumulatedList: number[], choice: ISessionChoice) => {
    return accumulatedList.indexOf(choice.modifierId) === -1 ? [...accumulatedList, choice.modifierId] : accumulatedList;
  }, []);
};

// Locate the product in the context to get the sort order, since the productModifier list is always sorted
export const sortModifierIds = (modifierIds: number[], productId: number, context: IContextDto | null) => {
  if (!context) {
    return modifierIds;
  }

  let product = context.products.find((p) => p.id === productId);
  if (!product) {
    return modifierIds;
  }

  let productModifierIds = product.modifiers.map((productModifier: IProductModifierDto) => productModifier.modifierId);
  return productModifierIds.filter((modifierId) => modifierIds.indexOf(modifierId) > -1);
};

export const getSummarizedOrderList = (items: ISessionItem[]) => {
  const getMergedProduct = (productA: ISessionItem, productB: ISessionItem) => {
    return {
      ...productA,
      ...productB,
      amount: productA.amount + productB.amount,
      totalPrice: productA.totalPrice + productB.totalPrice,
      choices: [...productA.choices, ...productB.choices],
    };
  };

  return items.reduce((summarizedList: ISessionItem[], currentItem: ISessionItem) => {
    let updatedList = summarizedList;
    let foundIndex = updatedList.findIndex((listItem) => listItem.productId === currentItem.productId);

    // Amount of choices is multiplied by amount of the product to display total amount in orderlist
    let itemWithChoices = { ...currentItem, choices: [...currentItem.choices].map((choice) => ({ ...choice, amount: choice.amount * currentItem.amount })) };

    if (foundIndex === -1) {
      updatedList.push(itemWithChoices);
      return updatedList;
    }

    let updatedItem = getMergedProduct(updatedList[foundIndex], itemWithChoices);
    updatedList[foundIndex] = updatedItem;

    return updatedList;
  }, []);
};

export const getSummarizedChoices = (choiceList: ISessionChoice[]) => {
  return choiceList.reduce((summarizedList: ISessionChoice[], currentChoice: ISessionChoice) => {
    let updatedList = summarizedList;
    let foundIndex = updatedList.findIndex((listItem) => listItem.choiceId === currentChoice.choiceId);

    if (foundIndex === -1) {
      updatedList.push(currentChoice);
      return updatedList;
    }

    let updatedChoice = updatedList[foundIndex];
    updatedList[foundIndex] = { ...updatedChoice, amount: updatedChoice.amount + currentChoice.amount };

    return updatedList;
  }, []);
};
