import { IBasketItemDto, IBasketItemChoiceDto, IBlockedChoiceDto, IChoiceDto, IContextDto, IModifierDto, IProductDto, IProductModifierDto, ISelectedChoice, ISelectedChoices } from "@crunchit/types";
import { filterModifiersForSoldOutChoices, getTotalChoiceCount, hasReachedMaxChoices } from "@crunchit/utilities";
import { IBasketChoiceToAdd } from "models/basket";
import { useCallback, useState } from "react";

import { basketThunks, useBasketSelector } from "store/basket";
import { useCustomDispatch } from "store/useStore";
import useTracking from "tracking/useTracking";

import { getTotalChoicePriceForModifier } from "utils/helpers/menu";

export default function useModifiers(product: IProductDto, context: IContextDto) {
  const { basket, basketProducts } = useBasketSelector();
  const dispatch = useCustomDispatch();

  const { trackCartEvent } = useTracking();

  let [selectedChoices, setSelectedChoices] = useState<ISelectedChoices>({});

  const getModifiers = useCallback(
    (blockedChoices: IBlockedChoiceDto[]): IModifierDto[] => {
      const productModifierIds = product.modifiers.map((mod) => mod.modifierId); // This list has sortOrder and is already sorted so we use that order
      const blockedChoiceIds = blockedChoices.map((b) => b.choiceId);
      return filterModifiersForSoldOutChoices(context.modifiers, productModifierIds, blockedChoiceIds);
    },
    [product, context]
  );

  const getProductModifier = useCallback((modifierId: number) => product.modifiers.find((m: IProductModifierDto) => m.modifierId === modifierId), [product]);

  const updateChoiceCount = useCallback(
    (choiceId: number, modifier: IModifierDto, newCount: number) => {
      let continueToNextModifier = false;

      const choice = context.choices.find((c: IChoiceDto) => c.id === choiceId);
      const productModifier = getProductModifier(modifier.id);

      if (choice && productModifier) {
        let updatedSelectedChoices = Object.assign({}, selectedChoices);
        let updatedSelectedChoicesList = updatedSelectedChoices[modifier.id] ? updatedSelectedChoices[modifier.id] : [];

        let existingChoiceIndex = updatedSelectedChoicesList.findIndex((c: ISelectedChoice) => c.choiceId === choice.id);
        if (existingChoiceIndex > -1) {
          // Existing choice being updated
          let updatedSelectedChoice = updatedSelectedChoicesList[existingChoiceIndex];
          updatedSelectedChoice.amount = newCount;
          updatedSelectedChoicesList[existingChoiceIndex] = updatedSelectedChoice;
        } else {
          // New choice
          let newSelectedChoice = { choiceId: choice.id, name: choice.name["da-DK"] as string, amount: newCount };
          updatedSelectedChoicesList.push(newSelectedChoice);
        }

        // Finally, updating the whole selected modifier object
        updatedSelectedChoices[modifier.id] = updatedSelectedChoicesList;
        setSelectedChoices(updatedSelectedChoices);

        const updatedTotalChoiceCount = updatedSelectedChoicesList.reduce((total: number, current: ISelectedChoice) => total + current.amount, 0);
        if (hasReachedMaxChoices(updatedTotalChoiceCount, productModifier.maxChoices)) {
          continueToNextModifier = true;
        }
      }
      return continueToNextModifier;
    },
    [context, getProductModifier, selectedChoices]
  );

  const getTotalPriceForModifiers = useCallback(
    (modifiers: IModifierDto[], productQuantity: number) => {
      let price = product.price * productQuantity;

      for (let key in selectedChoices) {
        if (selectedChoices.hasOwnProperty(key)) {
          const modifierId = Number(key);
          const modifier = modifiers.find((m) => m.id === modifierId);
          const productModifier = getProductModifier(modifierId);

          if (modifier && productModifier) {
            const choicePrice = getTotalChoicePriceForModifier(modifier, productModifier?.choicesIncluded, context.choices, selectedChoices[Number(key)], productQuantity);
            price += choicePrice;
          }
        }
      }
      return price;
    },
    [product, context, selectedChoices, getProductModifier]
  );

  const minChoicesReached = useCallback(
    (modifier: IModifierDto, productQuantity: number) => {
      const productModifier = getProductModifier(modifier.id);

      if (!productModifier || !productModifier.minChoices) {
        return true;
      }

      const selected = selectedChoices[modifier.id] || [];
      const choiceCount = getTotalChoiceCount(selected);

      return choiceCount >= productModifier.minChoices * productQuantity;
    },
    [selectedChoices, getProductModifier]
  );

  const includedChoicesReached = useCallback(
    (modifier: IModifierDto, productQuantity: number) => {
      const productModifier = getProductModifier(modifier.id);

      if (!productModifier || !productModifier.minChoices) {
        return true;
      }

      const selected = selectedChoices[modifier.id] || [];
      const choiceCount = getTotalChoiceCount(selected);

      return choiceCount >= productModifier.choicesIncluded * productQuantity;
    },
    [selectedChoices, getProductModifier]
  );

  const finalize = async (productQuantity: number) => {
    // Checking for this product in basket, and add up if it exists
    let matchingProductsInBasket = basketProducts.filter((basketProduct) => basketProduct.productId === product.id);
    let amountAlreadyInBasket = matchingProductsInBasket.reduce((totalAmount, currentBasketProduct) => totalAmount + currentBasketProduct.amount, 0);

    let choicesAlreadyInBasket = matchingProductsInBasket.reduce((totalChoices: IBasketItemChoiceDto[], currentBasketProduct: IBasketItemDto) => {
      let amount = currentBasketProduct.amount;
      let productChoices = currentBasketProduct.choices.map((choice) => ({ ...choice, amount: choice.amount * amount }));
      return [...totalChoices, ...productChoices];
    }, []);

    let quantityToAdd = productQuantity + amountAlreadyInBasket;
    let choicesToAdd: IBasketChoiceToAdd[] = choicesAlreadyInBasket;

    // Adding choices just selected by the user
    const addChoices = (modifierId: number, selectedChoiceList: ISelectedChoice[]) => {
      const mappedChoices = selectedChoiceList
        .map<IBasketChoiceToAdd>((sChoice: ISelectedChoice) => {
          return { ...sChoice, modifierId };
        })
        .filter((c) => c.amount > 0);

      choicesToAdd = choicesToAdd.concat(mappedChoices);
    };

    for (let key in selectedChoices) {
      if (selectedChoices.hasOwnProperty(key)) {
        const modifierId = Number(key);
        const selectedChoiceList = selectedChoices[key];

        addChoices(modifierId, selectedChoiceList);
      }
    }

    const addBasketItem = async (choices: IBasketChoiceToAdd[], count = 1) => {
      // Items with modifiers are always added as new, not updated with amount
      await dispatch(basketThunks.addBasketItem({ product, count, choices }));

      // Track product add
      trackCartEvent("ADD", { product, choices });
    };

    const removeBasketItem = async (basketProduct: IBasketItemDto) => {
      if (basketProduct) {
        await dispatch(basketThunks.removeBasketItem({ basketId: basket.id, basketProductId: basketProduct.id }));

        // Track product remove
        trackCartEvent("REMOVE", { product: basketProduct });
      }
    };

    // Removing the previous basket items before adding (to avoid overlap in amounts)
    for (let ii = 0; ii < matchingProductsInBasket.length; ii++) {
      await removeBasketItem(matchingProductsInBasket[ii]);
    }

    if (quantityToAdd > 1) {
      // Keep track of modifiers and their choices to be added
      const choiceMap: { [modifierId: number]: IBasketChoiceToAdd[] } = {};

      product.modifiers.forEach((productModifier) => {
        const choices = choicesToAdd.filter((c) => c.modifierId === productModifier.modifierId);

        const copiedChoices = choices.map((c) => {
          return { ...c };
        });

        choiceMap[productModifier.modifierId] = copiedChoices;
      });

      for (let i = quantityToAdd; i > 0; i--) {
        const singleProductChoicesToAdd: IBasketChoiceToAdd[] = [];

        for (let j = 0; j < product.modifiers.length; j++) {
          const productModifier = product.modifiers[j];
          const choices = choiceMap[productModifier.modifierId];

          if (!choices.length) {
            continue;
          }

          const totalChoiceAmount = choices.reduce((acc, choice) => acc + choice.amount, 0);
          const totalAmountToTake = Math.ceil(totalChoiceAmount / i);

          let updatedChoices: IBasketChoiceToAdd[] = [];

          // Determine how to split the choices for this modifier
          // Check if it adds up if we grab the first occuring choice as a whole
          if (totalAmountToTake === choices[0].amount) {
            const choicesToAdd = choices.splice(0, 1);
            singleProductChoicesToAdd.push(...choicesToAdd);

            updatedChoices = choices;
          } else {
            // Split by choice amounts, i.e. only take some amount of the choices and share them with the next product
            let leftToTake = totalAmountToTake;

            updatedChoices = choices.map((choice, i) => {
              if (leftToTake > 0 && choice.amount > 0) {
                let amountToTakeFromChoice = 0;

                // If it's the last choice, grab the rest of what's needed
                if (i + 1 >= choices.length) {
                  amountToTakeFromChoice = leftToTake;
                } else {
                  const ratio = leftToTake / totalChoiceAmount; // What percentage of choice amount to grab
                  amountToTakeFromChoice = Math.ceil(ratio * choice.amount);
                }

                leftToTake -= amountToTakeFromChoice;
                singleProductChoicesToAdd.push({ ...choice, amount: amountToTakeFromChoice });

                const amountChoiceHasLeft = Math.max(0, choice.amount - amountToTakeFromChoice);

                return { ...choice, amount: amountChoiceHasLeft };
              }

              return choice;
            });
          }

          // Update the array of choice and their amounts for the next product
          choiceMap[productModifier.modifierId] = updatedChoices;
        }

        await addBasketItem(singleProductChoicesToAdd);
      }
    } else {
      await addBasketItem(choicesToAdd, quantityToAdd);
    }
  };

  return {
    getModifiers,
    getProductModifier,
    selectedChoices,
    updateChoiceCount,
    minChoicesReached,
    includedChoicesReached,
    getTotalPriceForModifiers,
    finalize,
  };
}
