import { IChoiceDto, IModifierDto, IModifierChoiceDto, ISelectedChoice } from "@crunchit/types";
import { getSingleChoicePrice, ignoreIncludedChoices } from "@crunchit/utilities";

export const getTotalChoicePriceForModifier = (modifier: IModifierDto, choicesIncluded: number, choices: IChoiceDto[], selectedChoices: ISelectedChoice[], productQuantity: number) => {
  let totalChoicePrice = 0;

  const getChoiceOptions = (choiceId: number) => {
    const choice = choices.find((c: IChoiceDto) => c.id === choiceId);
    const modifierChoice = modifier.choices.find((c: IModifierChoiceDto) => c.choiceId === choiceId);
    return { choice, modifierChoice };
  };

  const getChoicePrice = (selectedChoice: ISelectedChoice) => {
    const { choice, modifierChoice } = getChoiceOptions(selectedChoice.choiceId);
    return choice && modifierChoice ? getSingleChoicePrice(choice.defaultPrice, modifierChoice.overwritePrice) : 0;
  };

  // We cannot combine includedChoices and freeAmount, so we prioritize freeAmount
  const ignoreIncluced = ignoreIncludedChoices(modifier.choices);
  if (ignoreIncluced) {
    // Modifier has no includedChoices, so we check each choice's free amount
    totalChoicePrice = selectedChoices.reduce((total: number, currentChoice: ISelectedChoice) => {
      let updatedTotal = total;

      const { choice, modifierChoice } = getChoiceOptions(currentChoice.choiceId);
      if (choice && modifierChoice) {
        const extraCount = currentChoice.amount - (modifierChoice.freeAmount * productQuantity);
        const extraPrice = extraCount > 0 ? extraCount * getSingleChoicePrice(choice.defaultPrice, modifierChoice.overwritePrice) : 0;
        updatedTotal = total + extraPrice;
      }
      return updatedTotal;
    }, totalChoicePrice);
  } else {
    // Sort by the most expensive choices to include and calculate price for the rest
    let sortedByPrice = selectedChoices.sort((a: ISelectedChoice, b: ISelectedChoice) => getChoicePrice(b) - getChoicePrice(a));

    let includedLeft = (choicesIncluded * productQuantity); // Keeping track of how many choices are included and counting down as we loop through
    let includedIndex = 0;

    while (includedIndex < sortedByPrice.length) {
      const singleSelectedChoice = sortedByPrice[includedIndex];
      
      if (singleSelectedChoice.amount > 0) {
        let amountToCharge = includedLeft > 0 ? 0 : singleSelectedChoice.amount; // How many of these choices are not included in the price

        if (includedLeft > 0 && includedLeft < singleSelectedChoice.amount) {
          // There are still included choices but the amount was higher than the included so we keep the leftover for price calculation
          // amountToCharge = amountToCharge-includedLeft;
          amountToCharge = singleSelectedChoice.amount - includedLeft;
        }

        totalChoicePrice += amountToCharge * getChoicePrice(singleSelectedChoice);
        includedLeft = includedLeft - singleSelectedChoice.amount;
      }

      includedIndex++;
    }
  }
  return totalChoicePrice;
};

