import { IProductDto } from "@crunchit/types";
import track from "./track";
import { getEvent, mapToGTM } from "./utils/helpers";
import { IMenuEventName } from "models/tracking";

interface IMenuEventParams {
  products: IProductDto[];
  categoryName: string;
  productionUnitName: string;
}

const impression = (params: IMenuEventParams): IGTMEvent => {
  const { products, categoryName, productionUnitName } = params;

  const eventData = {
    impressions: products.map((product, index) => {
      return {
        ...mapToGTM(product),
        position: index,
        category: categoryName,
        list: productionUnitName,
      };
    }),
  };

  return getEvent("productImpression", eventData);
};

const click = (params: IMenuEventParams): IGTMEvent => {
  const { products, categoryName, productionUnitName } = params;

  const eventData = {
    click: {
      actionField: {
        list: productionUnitName,
      },
      products: products.map((product, index) => {
        return {
          ...mapToGTM(product),
          position: index,
          ...(categoryName && { category: categoryName }),
        };
      }),
    },
  };

  return getEvent("productClick", eventData);
};

const detail = (params: IMenuEventParams): IGTMEvent => {
  const { products, categoryName, productionUnitName } = params;

  const eventData = {
    detail: {
      actionField: {
        list: productionUnitName,
      },
      products: products.map((product) => {
        return {
          ...mapToGTM(product),
          ...(categoryName && { category: categoryName }),
        };
      }),
    },
  };

  return getEvent("productDetailView", eventData);
};

const menuEvent = (eventName: IMenuEventName, params: IMenuEventParams): void => {
  switch (eventName) {
    case "CATEGORY_VIEWED":
      track(impression(params));
      break;

    case "MODIFIER_EXPANDED":
      track(click(params));
      track(detail(params));
      break;

    default:
      break;
  }
};

export default menuEvent;
