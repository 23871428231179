import i18n from "i18n";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/app";
import "./LanguagePicker.scss";

export default function LanguagePicker() {
  const { languages } = useAppSelector();
  const currentLanguage = i18n.language.split("-")[0];

  const switchLanguage = useCallback(() => {
    const languageToUse = languages.find((language) => {
      return !language.startsWith(currentLanguage);
    });

    if (languageToUse) {
      i18n.changeLanguage(languageToUse);
    }
  }, [currentLanguage, languages]);

  const imageSrc = useMemo(() => {
    switch (currentLanguage) {
      case "en":
        return "https://billedarkiv.blob.core.windows.net/images/96e3f70f-dd16-4255-8ddf-358fb6f7f600";

      default:
        return "https://billedarkiv.blob.core.windows.net/images/f46b958a-d2db-43fa-8b32-fd1f20a5e806";
    }
  }, [currentLanguage]);

  return <button className={`language-picker language-${currentLanguage}`} style={{ backgroundImage: `url(${imageSrc})` }} onClick={switchLanguage}></button>;
}
