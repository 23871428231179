import { IAvailableTimeDto } from "@crunchit/types";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useCheckoutSelector } from "store/checkout";

import Timeslots from "./Timeslots";
import "./Timepicker.scss";

const getTimepickerErrorTextKey = (isLoading: boolean, availableTimes: IAvailableTimeDto[], chosenDate?: string | null) => {
  if (isLoading) {
    return "common:Fetching";
  } else if (!chosenDate) {
    // No date
    return "errors:DeliveryMethod.Datepicker";
  } else if (!availableTimes || availableTimes.length === 0) {
    // There are no available timeslots for this date
    return "errors:DeliveryMethod.NoAvailableTimes";
  } else {
    return "errors:DeliveryMethod.NoAvailableTimes"; // Default message is no times
  }
};

interface ITimepickerProps {
  isOpen: boolean;
  availableTimes: IAvailableTimeDto[];
  disregardAvailability: boolean;
  showDeliveryTimeError: boolean;

  toggleIsOpen: () => void;
  handleTimeSelection: (updatedTimeslot: string) => void;
}

export default function Timepicker(props: ITimepickerProps) {
  const { isOpen, availableTimes, disregardAvailability, showDeliveryTimeError } = props;
  const { toggleIsOpen, handleTimeSelection } = props;

  const { t } = useTranslation();
  const { checkoutSession, isLoading: checkoutIsLoading, checkoutInstanceSettings } = useCheckoutSelector();
  const { isDelivery, chosenDate, chosenTimeslot } = checkoutSession;
  const { orderTimeInterval } = checkoutInstanceSettings;

  const inputPlaceholder = chosenTimeslot ? moment.utc(chosenTimeslot).format("HH:mm") : t("checkout:DeliveryMethod.Timepicker");

  return (
    <div className="timepicker">
      <input readOnly placeholder={inputPlaceholder} onClick={toggleIsOpen} className="timepicker-trigger" />

      {!chosenTimeslot && !showDeliveryTimeError && <div className="input-error">{t("errors:DeliveryMethod.TimePicker")}</div>}

      {showDeliveryTimeError && <div className="forced-input-error input-error">{t("errors:DeliveryMethod.DeliveryTime")}</div>}

      {isOpen && (
        <div className="timepicker-root">
          {availableTimes.length === 0 ? (
            <div className="timepicker-empty">
              <p>{t(getTimepickerErrorTextKey(checkoutIsLoading, availableTimes, chosenDate))}</p>
            </div>
          ) : (
            <div className="timeslot-view">
              <Timeslots isDelivery={isDelivery} disregardAvailability={disregardAvailability} orderTimeInterval={orderTimeInterval} availableTimes={availableTimes} handleTimeSelection={handleTimeSelection} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
