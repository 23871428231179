import { CheckoutDeliveryState, IAvailableDateDto, IAvailableTimeDto, IPickupTimesRequestBody } from "@crunchit/types";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

import CheckoutService from "services/CheckoutService";

import { useAppSelector } from "store/app";
import { useBasketSelector } from "store/basket";
import { checkoutActions, checkoutThunks, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";

import { addCheckoutDeliveryDate, addCheckoutDeliveryTime } from "utils/helpers/checkout/update";

export default function useAvailableTimes() {
  const { module, appSettings } = useAppSelector();
  const { checkout, checkoutSession, checkoutInstanceSettings } = useCheckoutSelector();
  const { basketProducts } = useBasketSelector({ allowCartFees: true });

  const { isDelivery, chosenDate, chosenTimeslot } = checkoutSession;

  const dispatch = useCustomDispatch();

  let [availableDates, setAvailableDates] = useState<Date[]>([]);
  let [availableTimes, setAvailableTimes] = useState<IAvailableTimeDto[]>([]);

  let [errorInDeliveryDate, setErrorInDeliveryDate] = useState(false); // This is an address error, but returned with the date
  let [errorInDeliveryTime, setErrorInDeliveryTime] = useState(false); // Error returned after adding deliveryTime

  // Show the datepicker if we have days to show, or if a default date is set
  let datepickerIsVisible = checkoutInstanceSettings.guestFutureOrderDates !== 0;

  // For fasttrack
  const disregardAvailability = !appSettings.enforceCapacity;

  const handeDeliveryDateChange = useCallback(
    async (checkoutId: string, date: string) => {
      dispatch(checkoutActions.setIsLoading({ isLoading: true }));
      await addCheckoutDeliveryDate(checkoutId, date);
      dispatch(checkoutThunks.loadCheckout());
    },
    [dispatch]
  );

  const updatePickupDates = useCallback(async () => {
    dispatch(checkoutActions.setIsLoading({ isLoading: true }));

    let dates: Date[] = [];

    try {
      const pickupDatesResponse = await CheckoutService.getAvailablePickupDates(module.checkoutInstanceId, module.productionUnitId);

      if (pickupDatesResponse.isSuccess()) {
        dates = pickupDatesResponse.data.map((dateObj: IAvailableDateDto) => new Date(dateObj.date));
      }
    } catch (error) {
      console.error(error);
    }

    setAvailableDates(dates);
    dispatch(checkoutActions.setIsLoading({ isLoading: false }));
  }, [module, dispatch]);

  const updateDeliveryDates = useCallback(async () => {
    if (!checkout.delivery || checkout.delivery.state === CheckoutDeliveryState.INITIAL) {
      // Address resat - empty dates list
      setAvailableDates([]);
      return;
    }

    if (checkout.delivery.state === CheckoutDeliveryState.ADDRESS_CHOSEN) {
      setErrorInDeliveryDate(false);

      dispatch(checkoutActions.setIsLoading({ isLoading: true }));

      let dates: Date[] = [];

      try {
        const deliveryDatesResponse = await CheckoutService.getAvailableDeliveryDates(checkout.id);

        if (deliveryDatesResponse.isSuccess()) {
          dates = deliveryDatesResponse.data.map((dateObj: IAvailableDateDto) => new Date(dateObj.date));

          // If no datepicker, we post the preselected date
          if (!datepickerIsVisible && chosenDate) {
            handeDeliveryDateChange(checkout.id, chosenDate);
            return;
          }
        } else {
          throw deliveryDatesResponse.errors;
        }
      } catch (error) {
        console.error(error);
        setErrorInDeliveryDate(true);
      }

      setAvailableDates(dates); // Will be set to empty if none of the conditions are met
      dispatch(checkoutActions.setIsLoading({ isLoading: false }));
    }
  }, [checkout.id, checkout.delivery, chosenDate, datepickerIsVisible, handeDeliveryDateChange, dispatch]);

  // Getting available pickup dates when data changes
  useEffect(() => {
    if (checkout.id) {
      if (!isDelivery) {
        updatePickupDates();
      } else {
        updateDeliveryDates();
      }
    }
  }, [isDelivery, checkout.id, updatePickupDates, updateDeliveryDates]);

  const handleDateChange = useCallback(
    async (date: string, isDelivery: boolean) => {
      dispatch(checkoutActions.setChosenDate(date));

      // POST date to checkout, if delivery
      if (isDelivery) {
        handeDeliveryDateChange(checkout.id, date);
      }
    },
    [checkout, handeDeliveryDateChange, dispatch]
  );

  const updatePickupTimes = useCallback(
    async (chosenDate?: string | null) => {
      dispatch(checkoutActions.setIsLoading({ isLoading: true }));

      let updatedAvailableTimes: IAvailableTimeDto[] = [];

      const pickup: IPickupTimesRequestBody = {
        productionUnitId: module.productionUnitId,
        date: moment.utc(chosenDate, "DD-MM-YYYY").format(),
        capacityInstanceId: module.capacityInstanceId,
        items: basketProducts,
      };

      try {
        const pickupTimesResponse = await CheckoutService.updatePickupTimes(module.checkoutInstanceId, pickup);

        if (pickupTimesResponse.isSuccess()) {
          updatedAvailableTimes = pickupTimesResponse.data.sort((a: IAvailableTimeDto, b: IAvailableTimeDto) => moment(a.time).valueOf() - moment(b.time).valueOf());
        }
      } catch (error) {
        console.error(error);
      }

      setAvailableTimes(updatedAvailableTimes);
      dispatch(checkoutActions.setIsLoading({ isLoading: false }));
    },
    // basketProducts cannot be included, since we temporarily do not wish to update the available times after updating basket
    // eslint-disable-next-line
    [module.productionUnitId, module.capacityInstanceId, module.checkoutInstanceId, dispatch]
  );

  const updateDeliveryTimes = useCallback(async () => {
    if (!checkout.delivery || checkout.delivery.state < CheckoutDeliveryState.DATE_CHOSEN) {
      // Address or date resat - empty times list
      setAvailableTimes([]);
      return;
    }

    if (checkout.delivery.state === CheckoutDeliveryState.DATE_CHOSEN) {
      dispatch(checkoutActions.setIsLoading({ isLoading: true }));

      let updatedAvailableTimes: IAvailableTimeDto[] = [];

      try {
        const availableTimesResponse = await CheckoutService.getAvailableDeliveryTimes(checkout.id);

        if (availableTimesResponse.isSuccess()) {
          updatedAvailableTimes = availableTimesResponse.data.sort((a: IAvailableTimeDto, b: IAvailableTimeDto) => moment(a.time).valueOf() - moment(b.time).valueOf());
        }
      } catch (error) {
        console.error(error);
      }

      setAvailableTimes(updatedAvailableTimes);
      dispatch(checkoutActions.setIsLoading({ isLoading: false }));
    }
  }, [checkout.id, checkout.delivery, dispatch]);

  useEffect(() => {
    // Triggered whenever the deliveryMethod or checkout updates
    if (checkout.id) {
      if (!chosenDate) {
        setAvailableTimes([]);
      } else {
        if (!isDelivery) {
          updatePickupTimes(chosenDate);
        } else {
          updateDeliveryTimes();
        }
      }
    }
  }, [isDelivery, checkout.id, chosenDate, updatePickupTimes, updateDeliveryTimes]);

  const handleTimeChange = useCallback(
    async (isDelivery: boolean, chosenTime?: string) => {
      if (!chosenTime) {
        dispatch(checkoutActions.setChosenTimeslot(null));
        return;
      }

      if (!isDelivery) {
        dispatch(checkoutActions.setChosenTimeslot(chosenTime));
        return;
      }

      dispatch(checkoutActions.setChosenTimeslot(null)); // Resetting to clear errors etc.
      dispatch(checkoutActions.setIsLoading({ isLoading: true, loadingMessageKey: "checkout:SpinnerMessage.DeliveryFee" }));

      const deliveryTimeResponse = await addCheckoutDeliveryTime(checkout.id, chosenTime);

      if (deliveryTimeResponse.isSuccess()) {
        dispatch(checkoutActions.setChosenTimeslot(chosenTime));
        dispatch(checkoutThunks.loadCheckout());
      } else {
        dispatch(checkoutActions.setIsLoading({ isLoading: false }));
        setErrorInDeliveryTime(true);
      }
    },
    [checkout, dispatch]
  );

  useEffect(() => {
    // If we reset the chosen time, we reset the error
    setErrorInDeliveryTime(false);
  }, [chosenTimeslot]);

  useEffect(() => {
    if (availableDates.length) {
      // If we have dates, we reset the date error
      setErrorInDeliveryDate(false);
    }
  }, [availableDates.length]);

  return {
    datepickerIsVisible,
    disregardAvailability,

    availableDates,
    availableTimes,

    errorInDeliveryDate,
    errorInDeliveryTime,

    handleDateChange,
    handleTimeChange,
  };
}
