import { formatPrice } from "@crunchit/utilities";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

import basketIconSrc from "assets/img/icons/basket.svg";
import whiteBasketIconSrc from "assets/img/icons/basket_white.svg";
import { useAppSelector } from "store/app";
import { useBasketSelector } from "store/basket";

import "./SummaryBasket.scss";

interface ISummaryBasketProps {
  handleClick: () => void;
}

export default function SummaryBasket(props: ISummaryBasketProps) {
  const { handleClick } = props;

  const { t } = useTranslation();
  const { appIsEvent } = useAppSelector();
  const { basketProductTotal, basketProductCount } = useBasketSelector();

  let hasProducts = useMemo(() => {
    return basketProductCount > 0;
  }, [basketProductCount]);

  let iconSrc = useMemo(() => {
    return hasProducts ? whiteBasketIconSrc : basketIconSrc;
  }, [hasProducts]);

  return (
    <button className={`click-allowed summarycart ${hasProducts ? "active" : ""}`} onClick={handleClick}>
      {hasProducts && <div className="ordernumber">{basketProductCount}</div>}

      <img src={iconSrc} alt="" />

      <div className="click-allowed ordertotal">
        <span>{appIsEvent ? t("cart:CartTitleEvent") : t("cart:CartTitle")}</span>
        <span className="total">
          {formatPrice(basketProductTotal)} {t("common:Currency")}
        </span>
      </div>
    </button>
  );
}
