import { ICategoryDto, IContextDto, IProductDto } from "@crunchit/types";
import { useMemo } from "react";

import { useCustomSelector } from "store/useStore";
import { useAppSelector } from "store/app";

function isValidCategory(category: ICategoryDto, context: IContextDto) {
  if (!category.products.length) {
    return false;
  }

  const products = category.products.map((c) => context.products.find((p) => p.id === c.productId));
  const filteredProducts = products.filter((p) => p !== undefined) as IProductDto[];

  return filteredProducts.some((p) => p.price > 0);
}

// Computing values from redux state
export default function useSelector() {
  const state = useCustomSelector((state) => state.menu);

  const { categories, context } = state;
  const { appSettings } = useAppSelector();

  const precheckoutCategoryId = appSettings.precheckoutCategory;

  const precheckoutCategory = useMemo(() => {
    if (!context) {
      return;
    }

    const precheckoutCategory = categories.find((c: ICategoryDto) => c.id === precheckoutCategoryId);

    if (precheckoutCategory && isValidCategory(precheckoutCategory, context)) {
      return precheckoutCategory;
    }
  }, [context, categories, precheckoutCategoryId]);

  const filteredCategories = useMemo(() => {
    let cats: ICategoryDto[] = categories || [];

    // Filtering out the precheckout category
    if (precheckoutCategoryId) {
      cats = cats.filter((c: ICategoryDto) => c.id !== precheckoutCategoryId);
    }

    if (context) {
      cats = cats.filter((c: ICategoryDto) => isValidCategory(c, context));
    }

    return cats;
  }, [context, categories, precheckoutCategoryId]);

  return {
    ...state,
    categories: filteredCategories,
    precheckoutCategory,
    context,
  };
}
