import { get } from "@crunchit/http-client";
import { IFrontendSettingsDto, ILanguageSettingDto } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_CUSTOMERSETTING_URI || `customersetting.${getEnvironment()}crunchy.ninja/api`;

const CustomerSettingService = {
  getFrontendSettingsByModule: async (moduleId: string) => {
    return get<IFrontendSettingsDto>(`${api}/frontend/module/${moduleId}`, getToken());
  },
  getLanguage: async () => {
    return get<ILanguageSettingDto>(`${api}/language`, getToken());
  },
};

export default CustomerSettingService;
