import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICheckoutDto, ICheckoutDeliveryDto, ICheckoutDiscountDto, ICheckoutGiftcardDto, ICheckoutGuestInformationDto, ICheckoutInstanceSettingsDto } from "@crunchit/types";

import { loadCheckout } from "./thunks";
import { checkoutSessionFactory } from "./utils/factory";
import { ICheckoutState, ICriticalError } from "models/store";

/* Checkout slice */

const initialState: ICheckoutState = {
  checkoutLoadingMessageKey: "",
  checkout: null,
  checkoutInstanceSettings: null,
  checkoutSession: null,
  isLoading: false,
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ isLoading: boolean; loadingMessageKey?: string }>) => {
      const { isLoading, loadingMessageKey } = action.payload;
      state.isLoading = isLoading;
      state.checkoutLoadingMessageKey = isLoading && loadingMessageKey ? loadingMessageKey : "";
    },
    setCheckout: (state, action: PayloadAction<ICheckoutDto | null>) => {
      state.checkout = action.payload;
    },
    setCheckoutInstanceSettings: (state, action: PayloadAction<ICheckoutInstanceSettingsDto | null>) => {
      state.checkoutInstanceSettings = action.payload;
    },

    // Checkout session
    resetCheckoutSession: (state) => {
      state.checkoutSession = null;
    },
    setShowValidationErrors: (state, action: PayloadAction<boolean>) => {
      let updatedCheckoutSession = checkoutSessionFactory(state.checkoutSession);
      updatedCheckoutSession.showValidationErrors = action.payload;
      state.checkoutSession = updatedCheckoutSession;
    },
    setBringOwnBagIsChecked: (state, action: PayloadAction<boolean>) => {
      let updatedCheckoutSession = checkoutSessionFactory(state.checkoutSession);
      updatedCheckoutSession.bringOwnBagIsChecked = action.payload;
      state.checkoutSession = updatedCheckoutSession;
    },
    setIsDelivery: (state, action: PayloadAction<boolean>) => {
      let updatedCheckoutSession = checkoutSessionFactory(state.checkoutSession);
      updatedCheckoutSession.isDelivery = action.payload;

      // Also resetting related values
      updatedCheckoutSession.delivery = null;
      updatedCheckoutSession.chosenTimeslot = null;

      if (state.checkoutInstanceSettings?.guestFutureOrderDates) {
        updatedCheckoutSession.chosenDate = null;
      }

      // If delivery; force bag fee
      if (action.payload) {
        updatedCheckoutSession.bringOwnBagIsChecked = false;
      }

      state.checkoutSession = updatedCheckoutSession;
    },
    setChosenDate: (state, action: PayloadAction<string | null>) => {
      let updatedCheckoutSession = checkoutSessionFactory(state.checkoutSession);
      updatedCheckoutSession.chosenDate = action.payload;
      updatedCheckoutSession.chosenTimeslot = null;
      state.checkoutSession = updatedCheckoutSession;
    },
    setChosenTimeslot: (state, action: PayloadAction<string | null>) => {
      let updatedCheckoutSession = checkoutSessionFactory(state.checkoutSession);
      updatedCheckoutSession.chosenTimeslot = action.payload;
      state.checkoutSession = updatedCheckoutSession;
    },
    setCheckoutSessionGuestInfo: (state, action: PayloadAction<ICheckoutGuestInformationDto>) => {
      let updatedCheckoutSession = checkoutSessionFactory(state.checkoutSession);
      updatedCheckoutSession.guestInformation = action.payload;
      state.checkoutSession = updatedCheckoutSession;
    },
    setCheckoutSessionDelivery: (state, action: PayloadAction<Partial<ICheckoutDeliveryDto> | null>) => {
      let updatedCheckoutSession = checkoutSessionFactory(state.checkoutSession);
      updatedCheckoutSession.delivery = action.payload;

      if (state.checkoutInstanceSettings?.guestFutureOrderDates) {
        updatedCheckoutSession.chosenDate = null;
      }

      updatedCheckoutSession.chosenTimeslot = null;
      state.checkoutSession = updatedCheckoutSession;
    },
    setCheckoutSessionDiscount: (state, action: PayloadAction<Partial<ICheckoutDiscountDto> | null>) => {
      let updatedCheckoutSession = checkoutSessionFactory(state.checkoutSession);
      updatedCheckoutSession.discount = action.payload;
      state.checkoutSession = updatedCheckoutSession;
    },
    setCheckoutSessionGiftcard: (state, action: PayloadAction<Partial<ICheckoutGiftcardDto> | null>) => {
      let updatedCheckoutSession = checkoutSessionFactory(state.checkoutSession);
      updatedCheckoutSession.giftcard = action.payload;
      state.checkoutSession = updatedCheckoutSession;
    },
    setError: (state, action: PayloadAction<ICriticalError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetError: (state) => {
      delete state.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCheckout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadCheckout.rejected, (state, action) => {
        state.error = action.payload;
        state.checkoutLoadingMessageKey = "";
        state.isLoading = false;
      })
      .addCase(loadCheckout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkout = action.payload;
      });
  },
});

export const slice = checkoutSlice.reducer;
export const actions = checkoutSlice.actions;
