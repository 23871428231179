import { usePayment } from "./usePayment";

import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";

import PaymentButtons from "./PaymentButtons";
import PaymentError from "./PaymentError";
import Terms from "./Terms";
import { useAppSelector } from "store/app";
import { useMemo } from "react";

export default function Payment() {
  const { appIsFasttrack } = useAppSelector();
  const { isLoading: checkoutIsLoading } = useCheckoutSelector();
  const dispatch = useCustomDispatch();

  const { allowedButtons, termsAccepted, showRestaurantOfflineError, missingFields, setTermsAccepted, validateCheckout, handlePayment } = usePayment();

  async function handlePaymentButtonClick(paymentType: string, additionalPaymentParameter?: string) {
    const validated = await validateCheckout();

    if (validated) {
      await handlePayment(paymentType, additionalPaymentParameter);
    } else {
      dispatch(checkoutActions.setIsLoading({ isLoading: false }));
    }
  }

  const paymentIsDisabled = useMemo(() => {
    if (appIsFasttrack) {
      return checkoutIsLoading;
    }

    return checkoutIsLoading || !termsAccepted;
  }, [appIsFasttrack, checkoutIsLoading, termsAccepted]);

  return (
    <div className="payment-section">
      <div className="finalize-buttons-body">
        {!appIsFasttrack && <Terms checked={termsAccepted} updateChecked={(checked: boolean) => setTermsAccepted(checked)} />}

        <PaymentButtons allowedButtons={allowedButtons} disabled={paymentIsDisabled} handlePaymentButtonClick={handlePaymentButtonClick} />
      </div>

      <PaymentError showRestaurantOfflineError={showRestaurantOfflineError} missingFields={missingFields} />

      <div className="paymentcards"></div>
    </div>
  );
}
