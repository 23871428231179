import { IBasketItemDto, IDiscountValidationRequestBody } from "@crunchit/types";
import DiscountService from "services/DiscountService";
import GiftcardService from "services/GiftcardService";

export async function validateDiscountCode(code: string, items: IBasketItemDto[], moduleId: string, discountInstanceId: string, posSettingInstanceId: string | null) {
  let validatedAmount = 0;
  let discountError = "";

  const discount: IDiscountValidationRequestBody = {
    discountCode: code,
    items: items,
  };

  try {
    const discountValidateResponse = await DiscountService.validateDiscount(discountInstanceId, moduleId, discount, posSettingInstanceId);

    if (discountValidateResponse.isSuccess()) {
      validatedAmount = discountValidateResponse.data.sumDiscount;
    } else {
      throw new Error("Discount code is invalid");
    }
  } catch (error) {
    if (error instanceof Error) {
      discountError = error.message;
    } else {
      discountError = "Unable to validate discount code";
    }
  }

  return { validatedAmount, discountError };
}

export async function validateGiftcardCode(code: string, giftcardInstanceId: string, posSettingInstanceId: string | null) {
  let validatedAmount = 0;
  let giftcardError;

  try {
    const giftcardValidateResponse = await GiftcardService.validateGiftcard(giftcardInstanceId, code, posSettingInstanceId);

    if (giftcardValidateResponse.isSuccess()) {
      validatedAmount = giftcardValidateResponse.data;
    } else {
      giftcardError = giftcardValidateResponse.errors;
    }
  } catch (error) {
    console.error(error);

    if (error instanceof Error) {
      giftcardError = error.message;
    } else {
      giftcardError = "Unable to validate gift card";
    }
  }

  return { validatedAmount, giftcardError };
}

export const getValidString = (str?: string) => (str !== undefined ? str.replace(/&/g, "&amp;").replace(/"/g, "").replace(/'/g, "").replace(/</g, "").replace(/>/g, "") : "");

export function isNumber(val: string) {
  let goodKey = "0123456789";
  let errorFound = false;

  for (let i = 0; i < val.length; i++) {
    const character = val.charAt(i);

    if (goodKey.indexOf(character) === -1) {
      errorFound = true;
    }
  }

  return !errorFound;
}

export const inputIsValid = (name: string, value?: string) => {
  if (!value || typeof value !== "string") {
    // All inputs are valid if empty - required is checked elsewhere
    return true;
  } else {
    let validated = true;

    switch (name) {
      case "phone":
        let phoneIsNumber = isNumber(value);
        validated = value.length > 7 && value.length < 21 && phoneIsNumber;
        break;
      case "email":
        let symbolCount = (value.match(/@/g) || []).length; // Making sure there's only 1 @ symbol
        validated = value !== undefined ? value.length > 3 && value.length < 101 && value.includes("@") && value.includes(".") && symbolCount === 1 : false;
        break;
      case "zipCode":
        let zipIsNumber = isNumber(value);
        validated = value.toString().length === 4 && zipIsNumber;
        break;
      default:
        validated = true;
    }

    return validated;
  }
};
