import { createAsyncThunk } from "@reduxjs/toolkit";
import { IBlockedProductDto, ICategoryDto, ICategoryProductDto, IContextDto, IMenuDto, IProductDto } from "@crunchit/types";

import { ICriticalError } from "models/store";
import MenuService from "services/MenuService";
import ProductService from "services/ProductService";
import ProductionUnitService from "services/ProductionUnitService";
import WarehouseService from "services/WarehouseService";

type Rejection = { rejectValue: ICriticalError };
type LoadMenuParams = { menuInstanceId: string; existingMenuId: string; productionUnitId: number };
type LoadMenuSuccess = { menuId: string; categories: ICategoryDto[]; context: IContextDto };

async function filterLunchCategory(categories: ICategoryDto[], productionUnitId: number) {
  const lunchCategoryIndex = categories.findIndex((c: ICategoryDto) => c.internalName.toLowerCase() === "frokostkategori");

  if (lunchCategoryIndex >= 0) {
    const lunchEndHour = 16;
    let restaurantIsOpenForLunch = false;

    try {
      const response = await ProductionUnitService.getOpeningHoursToday(productionUnitId);

      if (!response.isSuccess()) {
        throw new Error();
      }

      const openingHour = response.data.open.split(":")[0];
      restaurantIsOpenForLunch = parseInt(openingHour) < lunchEndHour;
    } catch (error) {
      console.error(error);
    }

    if (!restaurantIsOpenForLunch || new Date().getHours() >= lunchEndHour) {
      categories.splice(lunchCategoryIndex, 1);
    }
  }

  return categories;
}

function filterEmptyCategories(categories: ICategoryDto[], blockedProducts: IBlockedProductDto[], products: IProductDto[]) {
  const productIsBlocked = (categoryProduct: ICategoryProductDto) => blockedProducts.findIndex((blockedProduct) => blockedProduct.productId === categoryProduct.productId) >= 0;

  const productHasPriceZero = (categoryProduct: ICategoryProductDto) => {
    const product = products.find((p) => p.id === categoryProduct.productId);
    return product && product.price === 0;
  };

  return categories.filter((category) => {
    return category.products.length >= 0 && !category.products.every((categoryProduct) => productIsBlocked(categoryProduct) || productHasPriceZero(categoryProduct));
  });
}

export const loadMenu = createAsyncThunk<LoadMenuSuccess, LoadMenuParams, Rejection>("menu/loadCategories", async (data, { rejectWithValue }) => {
  const { menuInstanceId, existingMenuId, productionUnitId } = data;
  let menuId;

  try {
    if (existingMenuId) {
      menuId = existingMenuId;
    } else {
      const menusResponse = await MenuService.getMenus(menuInstanceId);

      if (!menusResponse.isSuccess()) {
        return rejectWithValue({ message: `Unable to fetch categories: no menu with menu instance id '${menuInstanceId}'`, error: menusResponse.errors });
      }

      const activeMenu = menusResponse.data.find((m: IMenuDto) => m.active);

      if (!activeMenu) {
        return rejectWithValue({ message: `No active menus within menu instance '${menuInstanceId}'` });
      }

      menuId = activeMenu.id.toString();
    }

    const [categoriesResponse, blockedProductsResponse] = await Promise.all([MenuService.getCategories(menuId, menuInstanceId), WarehouseService.getBlockedProducts(productionUnitId)]);
    let categories: ICategoryDto[] = [];

    if (categoriesResponse.isSuccess()) {
      categories = categoriesResponse.data;
    }

    let blockedProducts: IBlockedProductDto[] = [];

    if (blockedProductsResponse.isSuccess()) {
      blockedProducts = blockedProductsResponse.data;
    }

    categories = await filterLunchCategory(categories, productionUnitId);

    const categoryProducts = categories.flatMap((c) => c.products);
    const categoryProductIds = categoryProducts.map((c) => c.productId);

    const contextResponse = await ProductService.createContext(categoryProductIds);

    if (!contextResponse.isSuccess()) {
      return rejectWithValue({ message: "Unable to get context", error: contextResponse.errors });
    }

    const context = contextResponse.data;

    categories = filterEmptyCategories(categories, blockedProducts, context.products);

    return { menuId, categories, context };
  } catch (error) {
    return rejectWithValue({ message: "Unable to load categories", error: error instanceof Error ? error.message : error });
  }
});

const thunks = {
  loadMenu,
};

export default thunks;
