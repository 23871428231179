import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PlaceholderService from "services/PlaceholderService";
import { useAppSelector } from "store/app";

export default function PlaceholderBanner() {
  const { module } = useAppSelector();
  const { i18n } = useTranslation();

  const [content, setContent] = useState("");

  const loadContent = useCallback(async (instanceId: string, language: string) => {
    try {
      const response = await PlaceholderService.getPlaceholder(instanceId, 9, language);

      if (!response.isSuccess() || !response.data.isActive || !response.data.translations[language]) {
        return;
      }

      setContent(response.data.translations[language].content);
    } catch (error) {
      return;
    }
  }, []);

  useEffect(() => {
    if (module.placeholderInstanceId) {
      setContent("");
      loadContent(module.placeholderInstanceId, i18n.language);
    }
  }, [module.placeholderInstanceId, i18n.language, loadContent]);

  if (content) {
    return <div className="placeholder-banner" dangerouslySetInnerHTML={{ __html: content }}></div>;
  }

  return <></>;
}
