import { ISessionChoice } from "@crunchit/types";
import { useTranslation } from "react-i18next";
import { getSummarizedChoices } from "utils/helpers/orderlist";

export default function ChoiceListAmounts({ modifierIds, choices }: { modifierIds: number[]; choices: ISessionChoice[] }) {
  const { t } = useTranslation();

  const getChoicesForModifierId = (modifierId: number, choices: ISessionChoice[]) => {
    return getSummarizedChoices(choices.filter((choice) => choice.modifierId === modifierId));
  };

  return (
    <>
      {modifierIds.map((modifierId) => {
        return (
          <ul key={`${modifierId}-amounts`} className="orderlist-item-choice-list choices-amount">
            {getChoicesForModifierId(modifierId, choices).map((choice) => {
              return (
                <li key={choice.id}>
                  {choice.amount} {t("common:Unit")}
                </li>
              );
            })}
          </ul>
        );
      })}
    </>
  );
}
