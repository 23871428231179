import Topbar from "components/navigation/Topbar";
import Sidebar from "components/navigation/Sidebar";
import Basket from "components/basket/Basket";
import { useAppSelector } from "store/app";
import SubHeader from "../SubHeader";

import "./Header.scss";
import useHeaderNavigation from "./useHeaderNavigation";
import { ILayoutOptions } from "models/layout";

// A wrapper including the topbar header and expandable cart and navigation
export default function Header(props: Partial<ILayoutOptions>) {
  const { hideBasket, hideSidebar } = props;

  const { hideNavigation, basketIsOpen, basketIsClosing, sidebarIsOpen, sidebarIsClosing, clickHandlers } = useHeaderNavigation();
  const { productionUnit } = useAppSelector();

  return (
    <header className={`header-container ${basketIsOpen || sidebarIsOpen ? "expanded-header-container" : ""}`}>
      <Topbar hideBasket={hideBasket || hideNavigation} hideSidebar={hideSidebar || hideNavigation} clickHandlers={clickHandlers} />

      {sidebarIsOpen && <Sidebar isClosing={sidebarIsClosing} handleCategoryClick={clickHandlers.sidebarCategory} />}

      <SubHeader name={productionUnit?.name} hideBasket={hideBasket || hideNavigation} clickHandlers={clickHandlers} />

      {basketIsOpen && <Basket isClosing={basketIsClosing} handleContinue={clickHandlers.continue} handleBasketToggle={clickHandlers.basket} />}
    </header>
  );
}
