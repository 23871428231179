import { ICheckoutDto, CheckoutState } from "@crunchit/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import Page from "components/ui/page/Page";
import { CheckoutFailure } from "models/order";
import CheckoutService from "services/CheckoutService";
import { useCustomDispatch } from "store/useStore";
import { useAppSelector, appActions } from "store/app";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { customInsightsEvent } from "utils/helpers/errors";

export default function Order() {
  const { t } = useTranslation();
  const dispatch = useCustomDispatch();
  const redirect = useNavigate();
  const { appIsInitialized, appIsAuthenticated } = useAppSelector();
  const { checkout } = useCheckoutSelector();
  const { checkoutid } = useParams();

  const maxFailedAttempts = 3;
  const retryDelay = 1000;

  const [failure, setCheckoutFailure] = useState<CheckoutFailure | null>(null);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [isPaymentOrder, setIsPaymentOrder] = useState(false);

  const checkPaymentStatus = useMemo(() => appIsInitialized && isPaymentOrder && checkout && checkout.id.length > 0, [appIsInitialized, isPaymentOrder, checkout]);

  const handleError = useCallback(
    (errorMessage: string) => {
      if (checkoutid) {
        customInsightsEvent("CheckoutError", { CheckoutId: checkoutid, Message: errorMessage });
      }

      dispatch(appActions.setError({ message: "Order error", error: errorMessage }));
    },
    [checkoutid, dispatch]
  );

  const handleCheckoutIsDone = useCallback(
    (finalCheckout: ICheckoutDto) => {
      dispatch(checkoutActions.setCheckout(finalCheckout));
      redirect("/confirmation");
    },
    [dispatch, redirect]
  );

  // Sending the order here if there's no payment
  const handlePickupOrder = useCallback(
    async (checkout: ICheckoutDto) => {
      const finalCheckoutResponse = await CheckoutService.finalizeCheckout(checkout.id);

      if (!finalCheckoutResponse.isSuccess()) {
        throw new Error(`Unable to finalize checkout with id '${checkout.id}'`);
      }

      const finalCheckout = finalCheckoutResponse.data;

      if (finalCheckout.state === CheckoutState.DONE) {
        handleCheckoutIsDone(finalCheckout);
      } else {
        handleError(`Checkout with id '${checkout.id}' returned finalized state ${finalCheckout.state}`);
      }
    },
    [handleCheckoutIsDone, handleError]
  );

  // Interval to fetch checkout if payment order
  useEffect(() => {
    if (!checkPaymentStatus) {
      return;
    }

    let timer: any;

    timer = setInterval(async () => {
      if (!checkoutid) {
        return;
      }

      try {
        const updatedCheckoutResponse = await CheckoutService.getCheckoutById(checkoutid);

        if (updatedCheckoutResponse.isSuccess()) {
          const checkout = updatedCheckoutResponse.data;

          if (checkout.state === CheckoutState.ERROR) {
            throw new Error("Updated checkout is in error state");
          }

          if (checkout.state === CheckoutState.DONE) {
            handleCheckoutIsDone(checkout);
          }
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : `${error}`;
        setCheckoutFailure({ errorMessage: `Update in timer failed: ${errorMessage}` });
      }
    }, 2000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [checkPaymentStatus, isPaymentOrder, checkoutid, handleError, handleCheckoutIsDone]);

  useEffect(() => {
    if (!failure) {
      return;
    }

    const newFailedAttempts = failedAttempts + 1;

    if (newFailedAttempts > maxFailedAttempts) {
      handleError(failure.errorMessage);
    } else {
      setCheckoutFailure(null);
      setFailedAttempts(newFailedAttempts);

      if (failure.retry) {
        setTimeout(failure.retry, retryDelay);
      }
    }
  }, [failure, failedAttempts, handleError]);

  let initializeComponent = useCallback(async () => {
    if (!checkoutid) {
      handleError("No checkout id to initialize Order with");
      return;
    }

    try {
      const initialCheckoutResponse = await CheckoutService.getCheckoutById(checkoutid);

      if (!initialCheckoutResponse.isSuccess()) {
        throw new Error(`Unable to get initial checkout with checkout id '${checkoutid}'`);
      }

      const checkout = initialCheckoutResponse.data;
      dispatch(checkoutActions.setCheckout(checkout));

      // If the checkout has an external payment reference, that means it will be handles in a callback so we fetch status in intervals
      if (checkout.payment && checkout.payment.paymentId) {
        setIsPaymentOrder(true); // This will trigger the interval fetch to start
        return;
      }

      if (checkout.state === CheckoutState.DONE) {
        handleCheckoutIsDone(checkout);
        return;
      }

      handlePickupOrder(checkout);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : `${error}`;
      setCheckoutFailure({ errorMessage: `Initialize failed: ${errorMessage}`, retry: initializeComponent });
    }
  }, [checkoutid, dispatch, handleError, handlePickupOrder, handleCheckoutIsDone]);

  useEffect(() => {
    if (appIsAuthenticated) {
      initializeComponent();
    }
  }, [appIsAuthenticated, initializeComponent]);

  return (
    <div className="content-body order">
      <div className="spinner"></div>
      <Page title={t("pagetitles:OrderPage.Title")}></Page>
    </div>
  );
}
