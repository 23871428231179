import { IAvailableTimeDto } from "@crunchit/types";
import moment from "moment";
import { useTranslation } from "react-i18next";

import TimeslotsRow from "./TimeslotsRow";

interface ITimeslotsProps {
  isDelivery: boolean;
  orderTimeInterval: number;
  disregardAvailability: boolean;
  availableTimes: IAvailableTimeDto[];
  handleTimeSelection: (updatedTime: string) => void;
}

export default function Timeslots(props: ITimeslotsProps) {
  const { availableTimes, orderTimeInterval, disregardAvailability, isDelivery } = props;
  const { handleTimeSelection } = props;

  const { t } = useTranslation();

  function timesForHour(hour: number) {
    // Filtering for only the timeslots relevant to the hour showing
    const times = availableTimes.filter((t) => moment.utc(t.time).hour() === hour);
    return times ? times : [];
  }

  // Generating the list of hours to show in the timepickers
  let timeslotHoursList = [];
  if (availableTimes && availableTimes.length) {
    let startHour = moment.utc(availableTimes[0].time).hour();
    let endHour = moment.utc(availableTimes[availableTimes.length - 1].time).hour();

    timeslotHoursList.push(startHour);
    let currentHour = startHour;
    while (currentHour < endHour) {
      currentHour++;
      timeslotHoursList.push(currentHour);
    }
  }

  return (
    <div className="timeslot-view-desktop">
      <div className="timepicker-header">
        <h4>{isDelivery ? t("checkout:DeliveryMethod.DeliveryTime") : t("checkout:DeliveryMethod.PickupTime")}</h4>
      </div>

      <div className="timepicker-body">
        {timeslotHoursList.map(function (hour) {
          return <TimeslotsRow key={hour} hour={hour} availableTimesForHour={timesForHour(hour)} orderTimeInterval={orderTimeInterval} disregardAvailability={disregardAvailability} handleTimeSelection={handleTimeSelection} />;
        })}
      </div>
    </div>
  );
}
