import { deliverySettings, getCheckoutSettings, navigationSettings, orderSettings } from "settings";

export const defaultModule = {
  id: "",

  menuInstanceId: "",
  productInstanceId: "",
  checkoutInstanceId: "",

  capacityInstanceId: null,
  deliveryInstanceId: null,
  discountInstanceId: null,
  giftcardInstanceId: null,
  lavuInstanceId: null,
  paymentInstanceId: null,
  placeholderInstanceId: null,
  posSettingInstanceId: null,

  name: "",
  type: 0,
  productionUnitId: 0,

  isActive: true,
  isVisible: true,
};

export const defaultFrontendSettings = {
  showClosedFrontpageMessage: false,

  onlinePayment: false,
  mobilepay: false,
  payAtPickup: false,

  startCategory: undefined,
  precheckoutCategory: undefined,
  bagFeeItem: undefined,
  logo: "",
  smileyUrl: "https://www.findsmiley.dk/",
};

export const defaultAppSettings = {
  ...getCheckoutSettings(),
  ...navigationSettings,
  ...orderSettings,
  ...deliverySettings,
  ...defaultFrontendSettings,
};
