import { IProductDto } from "@crunchit/types";
import EventProduct from "../Product/EventProduct";
import Sidebar from "components/navigation/Sidebar";
import PageTitle from "components/ui/PageTitle";

import "./EventCategory.scss";

interface ICategoryProps {
  title: string;
  subtitle?: string;
  product?: IProductDto;
}

export default function Category(props: ICategoryProps) {
  const { title, subtitle, product } = props;

  return (
    <div className="event-category content-body sidebar-body">
      <PageTitle title={title} />

      <Sidebar />

      <div className="content">{product && <EventProduct product={product} categoryDescription={subtitle} />}</div>
    </div>
  );
}
