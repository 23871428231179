import { ISessionItem } from "@crunchit/types";
import { formatPrice } from "@crunchit/utilities";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ChoiceListAmounts, ChoiceListNames, ChoiceListPrices } from "./ChoiceList";
import { useMenuSelector } from "store/menu";
import { getModifierIds, sortModifierIds } from "utils/helpers/orderlist";

import "./OrderListItem.scss";

interface IOrderListItemProps {
  product: ISessionItem;

  showChoices?: boolean;
  hideEditOptions?: boolean; // Items cannot be edited at all, so hide the options
  disableEditOptions: boolean; // Temporarily not allowed to edit the items

  itemCountChanged: (productId: number, newCount: number) => void;
}

export default function OrderListItem(props: IOrderListItemProps) {
  const { product } = props;
  const { itemCountChanged } = props;

  const { context } = useMenuSelector();
  const { t } = useTranslation();

  const productEditIsDisabled = product.editingIsDisabled; // This particular product cannot be edited

  function handleItemRemove() {
    itemCountChanged(product.productId, 0);
  }

  const showChoices = useMemo(() => props.showChoices && product.choices.length > 0, [props.showChoices, product.choices]);

  const modifierIds = useMemo(() => {
    return showChoices ? sortModifierIds(getModifierIds(product.choices), product.productId, context) : [];
  }, [showChoices, product.productId, product.choices, context]);

  return (
    <li className={`orderlist-item ${productEditIsDisabled ? "disabled" : ""} ${showChoices ? "with-choices" : ""}`}>
      <div className="orderlist-item-col orderlist-item-amount">
        <span className="product-amount">
          {product.amount} {t("common:Unit")}
        </span>
        {showChoices && <ChoiceListAmounts modifierIds={modifierIds} choices={product.choices} />}
      </div>

      <div className="orderlist-item-col orderlist-item-name">
        <span className="product-name">{product.name}</span>
        {showChoices && <ChoiceListNames modifierIds={modifierIds} choices={product.choices} />}
      </div>

      <div className="orderlist-item-col orderlist-item-price">
        <span className="product-price">
          {formatPrice(product.totalPrice)} {t("common:Currency")}
        </span>
        {showChoices && <ChoiceListPrices modifierIds={modifierIds} choices={product.choices} />}
      </div>

      <div className="orderlist-item-col orderlist-item-delete">
        {!productEditIsDisabled && (
          <button className="button button-muted" onClick={handleItemRemove}>
            {t("cart:Remove")}
          </button>
        )}
      </div>
    </li>
  );
}
