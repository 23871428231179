import { ICheckoutDeliveryDto } from "@crunchit/types";
import { useCallback, useMemo, useState } from "react";

import { useAppSelector } from "store/app";
import { useBasketSelector } from "store/basket";
import { checkoutActions, checkoutThunks, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";

import { removeDeliveryInfo } from "utils/helpers/checkout/reset";
import { addCheckoutDeliveryAddress } from "utils/helpers/checkout/update";

export default function useDeliveryMethod() {
  const { module, appSettings } = useAppSelector();
  const { checkout } = useCheckoutSelector();
  const { basketProducts } = useBasketSelector({ allowCartFees: true });

  const dispatch = useCustomDispatch();

  let [errorInDeliveryAddress, setErrorInDeliveryAddress] = useState(false);

  let giftcardInBasket = useMemo(() => basketProducts.find((p) => p.isGiftcard) !== undefined, [basketProducts]);

  const handleDeliveryMethodUpdate = useCallback(
    async (updated: { isDelivery: boolean }) => {
      dispatch(checkoutActions.setIsDelivery(updated.isDelivery));

      // Remove the existing delivery object if it exists
      if (checkout && checkout.delivery) {
        const updatedCheckout = await removeDeliveryInfo(checkout);
        dispatch(checkoutActions.setCheckout(updatedCheckout));
      }
    },
    [checkout, dispatch]
  );

  const handleDeliveryAddressUpdate = useCallback(
    async (updatedDeliveryInfo: Partial<ICheckoutDeliveryDto>) => {
      const { address, zipCode } = updatedDeliveryInfo;

      setErrorInDeliveryAddress(false);
      dispatch(checkoutActions.setCheckoutSessionDelivery(updatedDeliveryInfo));

      if (address && zipCode && zipCode.length === 4) {
        // Ready to fetch possible dates
        dispatch(checkoutActions.setIsLoading({ isLoading: true }));

        // POST to checkout
        const deliveryAddressError = await addCheckoutDeliveryAddress(checkout.id, module.id, { address, zipCode });
        if (deliveryAddressError) {
          // Handling all errors as address errors
          setErrorInDeliveryAddress(true);
          dispatch(checkoutActions.setIsLoading({ isLoading: false }));
        } else {
          // No error - load the updated checkout
          dispatch(checkoutThunks.loadCheckout());
        }
      }
    },
    [module, checkout, dispatch]
  );

  return {
    deliveryIsVisible: module.deliveryInstanceId !== null && appSettings.deliveryEnabled,
    deliveryIsDisabled: giftcardInBasket,
    errorInDeliveryAddress,

    handleDeliveryMethodUpdate,
    handleDeliveryAddressUpdate,
  };
}
