import { useTranslation } from "react-i18next";
import { useBasketSelector } from "store/basket";

import chevronSrc from "assets/img/icons/chevron.svg";
import OrderList from "components/basket/OrderList/OrderList";
import BasketTotal from "components/basket/totals/BasketTotal";
import { useAppSelector } from "store/app";

import "./FullBasket.scss";

interface IFullBasketProps {
  isClosing: boolean;
  clickHandlers: {
    basket: () => void;
    continue: () => void;
  };
}

export default function FullBasket(props: IFullBasketProps) {
  const { clickHandlers, isClosing } = props;

  const { appIsEvent } = useAppSelector();
  const { basketProducts, basketProductTotal } = useBasketSelector();
  const { t } = useTranslation();

  return (
    <div className={`expanded-cart ${isClosing ? "closing" : ""}`}>
      <div className="content inner">
        <h1>{appIsEvent ? t("cart:CartTitleEvent") : t("cart:CartTitle")}</h1>

        <OrderList products={basketProducts} />

        {basketProducts.length > 0 && (
          <>
            <BasketTotal total={basketProductTotal} />
            <div className="cart-cta-button">
              <button className="button button-colored green" onClick={clickHandlers.continue}>
                {t("cart:GoToCheckout")}
              </button>
            </div>
          </>
        )}

        <div className="footer">
          <button onClick={clickHandlers.basket} style={{ backgroundImage: `url(${chevronSrc})` }}></button>
        </div>
      </div>
    </div>
  );
}
