import { IChoiceDto, IModifierChoiceDto, IProductModifierDto, ISelectedChoice } from "@crunchit/types";
import { useCallback } from "react";

import useChoices from "./useChoices";
import Choice from "./Choice";

import "./Choices.scss";

interface IChoiceListProps {
  choices: IChoiceDto[];
  modifierChoices: IModifierChoiceDto[];
  selectedChoices?: ISelectedChoice[];
  productModifier: IProductModifierDto;
  handleChoiceCountUpdate: (choiceId: number, newCount: number) => void;
}

export default function Choices(props: IChoiceListProps) {
  const { selectedChoices, choices, modifierChoices, productModifier, handleChoiceCountUpdate } = props;
  const { getChoice, getChoiceCount, disableChoiceIncrease } = useChoices(productModifier, modifierChoices, selectedChoices);

  const setupDefaultChoiceValue = useCallback(
    (modifierChoice: IModifierChoiceDto) => {
      if (modifierChoice.defaultChoiceAmount > 0) {
        handleChoiceCountUpdate(modifierChoice.choiceId, modifierChoice.defaultChoiceAmount);
      }
    },
    [handleChoiceCountUpdate]
  );

  return (
    <ul className="choice-list collapsible-content">
      {modifierChoices.map(function (modifierChoice, index) {
        const choice = getChoice(modifierChoice.choiceId, choices);
        return !choice ? null : <Choice key={index} choice={choice} choiceCount={getChoiceCount(modifierChoice)} disableChoiceIncrease={disableChoiceIncrease} handleChoiceCountUpdate={(newCount: number) => handleChoiceCountUpdate(modifierChoice.choiceId, newCount)} handleChoiceMount={() => setupDefaultChoiceValue(modifierChoice)} />;
      })}
    </ul>
  );
}
