import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import PlaceholderBanner from "components/banner/PlaceholderBanner";
import Header from "components/navigation/Header/Header";
import EventHeader from "components/navigation/Header/EventHeader";
import FasttrackHeader from "components/navigation/Header/FasttrackHeader";
import Spinner from "components/ui/Spinner";
import useAppState from "hooks/useAppState";
import useAppRedirects from "hooks/useAppRedirects";
import { IViewProps } from "models/layout";
import { useAppSelector } from "store/app";

// Performing all page checks necessary before loading content
export default function View(props: IViewProps) {
  const { pageKey, layoutOptions, children } = props;
  const { isStatusPage = false, hideBasket = false, hideSidebar = false } = layoutOptions || {};

  const { isError, isLoading, loadingMessageKey } = useAppState();
  const { appIsFasttrack, appIsEvent } = useAppSelector();
  const { pathname } = useLocation();

  const redirect = useNavigate();
  const redirectPath = useAppRedirects({ isError, isLoading });

  const showPlaceholderBanner = useMemo(() => pathname === "/" || pathname.startsWith("/menu"), [pathname]);

  const HeaderComponent = useMemo(() => {
    return appIsFasttrack ? FasttrackHeader : appIsEvent ? EventHeader : Header;
  }, [appIsFasttrack, appIsEvent]);

  useEffect(() => {
    if (redirectPath) {
      redirect(redirectPath);
    }
  }, [redirectPath, redirect]);

  return (
    <div className={`page-view ${isStatusPage ? "status-page" : ""} ${pageKey}-page ${appIsFasttrack ? "fasttrack" : ""} ${appIsEvent ? "event" : ""}`}>
      <HeaderComponent hideBasket={hideBasket} hideSidebar={hideSidebar} />

      {showPlaceholderBanner && <PlaceholderBanner />}

      <div className="page-view-content inner">
        {/* Content from each component */}
        {children}
      </div>

      {isLoading && <Spinner loadingMessageKey={loadingMessageKey} />}
    </div>
  );
}
