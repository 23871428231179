import navigationEvent from "./navigation";
import menuEvent from "./menu";
import cartEvent from "./cart";
import checkoutEvent from "./checkout";

const gtm = {
  navigationEvent,
  menuEvent,
  cartEvent,
  checkoutEvent,
};

export default gtm;
