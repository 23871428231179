import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAppSelector } from "store/app";

export default function useHeaderNavigation() {
  let [basketIsOpen, setBasketIsOpen] = useState(false);
  let [basketIsClosing, setBasketIsClosing] = useState(false);
  let [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  let [sidebarIsClosing, setSidebarIsClosing] = useState(false);

  const { appSettings, appIsFasttrack } = useAppSelector();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hideNavigation = appSettings.showClosedFrontpageMessage;

  const collapseAllNavigation = useCallback(() => {
    setBasketIsOpen(false);
    setSidebarIsOpen(false);
  }, []);

  // Custom redirect for header, with optional checks
  const handleRedirect = useCallback(
    (path: string) => {
      let continueRedirect = false;

      // Order redirect check
      if (window.location.href.indexOf("/order/") > -1 && path === "/") {
        let c = window.confirm(t("errors:Order.ConfirmRedirectMessage"));
        if (c) {
          continueRedirect = true;
        }
      } else {
        continueRedirect = true;
      }

      if (continueRedirect) {
        collapseAllNavigation();
        navigate(path);
      }
    },
    [t, collapseAllNavigation, navigate]
  );

  function closeSidebar() {
    setSidebarIsOpen(false);
    setSidebarIsClosing(false);
  }

  function toggleSidebar() {
    if (sidebarIsOpen) {
      setSidebarIsClosing(true);
      setTimeout(closeSidebar, 500);
    } else {
      setSidebarIsOpen(!sidebarIsOpen);
    }
  }

  function closeBasket() {
    setBasketIsOpen(false);
    setBasketIsClosing(false);
  }

  function toggleBasket() {
    if (basketIsOpen) {
      setBasketIsClosing(true);
      setTimeout(closeBasket, 500);
    } else {
      setBasketIsOpen(!basketIsOpen);
    }

    setSidebarIsOpen(false);
  }

  // Checking if our settings dictate the minicart always goes straight to checkout
  const handleMiniBasketClick = () => {
    if (appSettings.showExpandedCart) {
      toggleBasket();
    } else {
      // Fasttrack - go directly to checkout
      handleRedirect("/checkout");
    }
  };

  // Continue from cart to precheckout or checkout
  const handleContinueClick = () => {
    if (appIsFasttrack) {
      navigate("/checkout");
      return;
    }

    let path = appSettings.precheckoutCategory ? "/precheckout" : "/checkout";
    if (window.location.href.indexOf("precheckout") > -1) {
      path = "/checkout";
    }
    handleRedirect(path);
  };

  return {
    hideNavigation,
    basketIsOpen,
    basketIsClosing,
    sidebarIsOpen,
    sidebarIsClosing,
    handleRedirect,
    collapseAllNavigation,
    clickHandlers: {
      sidebar: toggleSidebar,
      sidebarCategory: (categoryId: number) => handleRedirect("/menu/" + categoryId),
      logo: () => handleRedirect("/"),
      basket: handleMiniBasketClick,
      continue: handleContinueClick,
    },
  };
}
