import { post } from "@crunchit/http-client";
import { IDiscountValidationRequestBody, IDiscountValidationResponseDto } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_DISCOUNT_URI || `discount.${getEnvironment()}crunchy.ninja/api`;

const DiscountService = {
  validateDiscount: async (instanceId: string, moduleId: string, discount: IDiscountValidationRequestBody, posSettingInstanceId: string | null) => {
    let params = `?instanceId=${instanceId}&moduleId=${moduleId}`;

    if (posSettingInstanceId) {
      params += `&posSettingInstanceId=${posSettingInstanceId}`;
    }

    return post<IDiscountValidationResponseDto>(`${api}/discount/validate${params}`, discount, getToken());
  },
};

export default DiscountService;
