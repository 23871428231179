import { IChoiceDto } from "@crunchit/types";
import useTranslationText from "hooks/useTranslationText";
import { useEffect } from "react";
import Counter from "components/ui/Counter";

interface IChoiceProps {
  choice: IChoiceDto;
  choiceCount: number;
  disableChoiceIncrease: boolean;
  handleChoiceCountUpdate: (newCount: number) => void;
  handleChoiceMount: () => void;
}

export default function Choice(props: IChoiceProps) {
  const { choice, choiceCount, disableChoiceIncrease } = props;
  const { handleChoiceCountUpdate, handleChoiceMount } = props;

  const { getTranslationText } = useTranslationText();

  useEffect(() => {
    handleChoiceMount();
    // eslint-disable-next-line
  }, []);

  return (
    <li className={`choice ${choiceCount > 0 ? "active" : ""}`}>
      <p className="choice-name">{choice ? getTranslationText(choice.name) : ""}</p>
      <Counter count={choiceCount} disableIncrease={disableChoiceIncrease} handleChange={handleChoiceCountUpdate} />
    </li>
  );
}
