import { ICheckoutGiftcardDto } from "@crunchit/types";
import { useCallback, useMemo, useState } from "react";

import { useAppSelector } from "store/app";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";

import { getGiftcardPaymentAmount } from "utils/helpers/checkout/session";
import { validateGiftcardCode } from "utils/helpers/checkout/validation";

export function useGiftcardValidation(giftcardInstanceId: string) {
  const { module } = useAppSelector();
  const { checkoutSession, getCheckoutPayments } = useCheckoutSelector();

  const dispatch = useCustomDispatch();

  let [validationIsLoading, setValidationIsLoading] = useState(false);
  let [errorMessageKey, setErrorMessageKey] = useState("");

  let validatedGiftcard = useMemo(() => (checkoutSession.giftcard ? checkoutSession.giftcard : {}), [checkoutSession.giftcard]);
  let { giftcardCode = "", amount = 0 } = validatedGiftcard;

  let giftcardPaymentAmount = useMemo(() => getGiftcardPaymentAmount(getCheckoutPayments()), [getCheckoutPayments]);

  let giftcardRemaining = amount - giftcardPaymentAmount;

  const handleGiftcardValidation = useCallback(
    async (giftcardCode: string) => {
      setValidationIsLoading(true);
      setErrorMessageKey("");

      const { validatedAmount, giftcardError } = await validateGiftcardCode(giftcardCode, giftcardInstanceId, module.posSettingInstanceId);

      if (giftcardError) {
        setErrorMessageKey("errors:Giftcard.Generic");

        dispatch(checkoutActions.setCheckoutSessionGiftcard(null));
      } else {
        const checkoutGiftcard: ICheckoutGiftcardDto = { giftcardCode: giftcardCode, amount: validatedAmount };
        dispatch(checkoutActions.setCheckoutSessionGiftcard(checkoutGiftcard));
      }

      setValidationIsLoading(false);
    },
    [module, giftcardInstanceId, dispatch]
  );

  const resetGiftcardSession = () => {
    setValidationIsLoading(false);
    setErrorMessageKey("");

    dispatch(checkoutActions.setCheckoutSessionGiftcard(null));
  };

  return {
    giftcardCode,
    amount,

    giftcardPaymentAmount,
    giftcardRemaining,

    validationIsLoading,
    errorMessageKey,

    handleGiftcardValidation,
    resetGiftcardSession,
  };
}
