import { useTranslation } from "react-i18next";

import "./PaymentButtons.scss";

interface IPaymentButtonsProps {
  allowedButtons: {
    onlinePayment: boolean;
    mobilepay: boolean;
    payAtPickup: boolean;
    placeOrder: boolean;
  };
  disabled: boolean;
  handlePaymentButtonClick: (paymentType: string, additionalPaymentParameter?: string) => void;
}

export default function PaymentButtons(props: IPaymentButtonsProps) {
  const { allowedButtons, disabled, handlePaymentButtonClick } = props;
  const { t } = useTranslation();

  return (
    <div className="finalize-buttons">
      {allowedButtons.payAtPickup && (
        <button onClick={() => handlePaymentButtonClick("payAtPickup")} className="button button-colored red" disabled={disabled}>
          {t("checkout:Payment.Buttons.PayAtPickup")}
        </button>
      )}

      {allowedButtons.placeOrder && (
        <button onClick={() => handlePaymentButtonClick("payAtPickup")} className="button fully-paid-button" disabled={disabled}>
          {t("checkout:Payment.Buttons.PlaceOrder")}
        </button>
      )}

      {allowedButtons.onlinePayment && (
        <button onClick={() => handlePaymentButtonClick("onlinePayment")} className="button button-colored green pay-online-button" disabled={disabled}>
          {t("checkout:Payment.Buttons.PayOnline")}
        </button>
      )}

      {allowedButtons.mobilepay && (
        <button onClick={() => handlePaymentButtonClick("onlinePayment", "mobilepay")} className="button mobilepay-button" disabled={disabled}>
          <div className="mobilepay-logo"></div>
          {t("checkout:Payment.Buttons.Mobilepay")}
        </button>
      )}
    </div>
  );
}
