import { useMemo } from "react";

import { useBasketSelector } from "store/basket";

import icon from "assets/img/icons/basket.svg";
import whiteIcon from "assets/img/icons/basket_white.svg";
import "./MiniBasket.scss";

interface IMiniBasketProps {
  handleClick: () => void;
}

export default function MiniBasket(props: IMiniBasketProps) {
  const { handleClick } = props;

  const { basketProductCount } = useBasketSelector();

  const hasProducts = useMemo(() => {
    return basketProductCount > 0;
  }, [basketProductCount]);

  const backgroundImage = useMemo(() => {
    return `url(${hasProducts ? whiteIcon : icon})`;
  }, [hasProducts]);

  return (
    <button className="click-allowed minicart" style={{ backgroundImage }} onClick={handleClick}>
      {basketProductCount > 0 && <span className="ordernumber">{basketProductCount}</span>}
    </button>
  );
}
