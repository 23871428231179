import { ICriticalError } from "@crunchit/types";

const isProduction = process.env.NODE_ENV === "production";

export function customInsightsEvent(eventName: string, data: { [key: string]: string }) {
  if (window.appInsights) {
    try {
      window.appInsights.trackEvent({ name: eventName, properties: data });
    } catch (err) {
      logError(err);
    }
  }
}

export const logError = (error: any) => {
  if (!isProduction) {
    console.log(error);
    console.trace(error);
  }
};

export const logICriticalError = (appError: ICriticalError) => {
  if (isProduction) {
    customInsightsEvent("AppError", { Message: appError.message });
  } else {
    console.error(appError);
  }
};
