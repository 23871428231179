import { IAvailableTimeDto } from "@crunchit/types";
import { useCallback, useMemo } from "react";
import moment from "moment";

// Generating the complete list of timeslots for this row, based on the interval in the checkout settings
const timeslots = (orderTimeInterval: number) => {
  let timeslotslist = ["00"];
  let i = orderTimeInterval;
  while (i < 60) {
    timeslotslist.push(i.toString().length !== 1 ? i.toString() : "0" + i.toString());
    i = i + orderTimeInterval;
  }
  return timeslotslist;
};

interface ITimeslotsRowProps {
  hour: number;
  orderTimeInterval: number;
  disregardAvailability: boolean;
  availableTimesForHour: IAvailableTimeDto[];
  handleTimeSelection: (updatedTime: string) => void;
}

export default function TimeslotsRow(props: ITimeslotsRowProps) {
  const { hour, availableTimesForHour, orderTimeInterval, disregardAvailability } = props;
  const { handleTimeSelection } = props;

  const timeslotsForHour = useMemo(() => timeslots(orderTimeInterval), [orderTimeInterval]);

  const availableTimeslot = useCallback(
    (timeslotInterval: string) => {
      return availableTimesForHour.find((t) => moment.utc(t.time).minute() === parseInt(timeslotInterval));
    },
    [availableTimesForHour]
  );

  function timeIntervalClicked(availableTimeslotFound?: IAvailableTimeDto) {
    let isAvailable = availableTimeslotFound?.isAvailable;

    if (disregardAvailability) {
      isAvailable = true;
    }

    if (availableTimeslotFound && isAvailable) {
      handleTimeSelection(availableTimeslotFound.time);
    }
  }

  function getActiveClassName(availableTimeslotFound?: IAvailableTimeDto) {
    // The classname to determine how we present the timeslot (unavailable, full capcity, etc)
    let activeClassName = "";

    if (availableTimeslotFound) {
      if (availableTimeslotFound.isAvailable) {
        activeClassName = "active";
      } else {
        if (disregardAvailability) {
          // Showing indication of full instead of unavailable, if we're diregarding whether they're available (e.g. on fasttrack)
          activeClassName = "unavailable";
        }
      }
    }
    return activeClassName;
  }

  return (
    <ul className="timepicker-row">
      <li className="timepicker-body-header">{hour}</li>
      {timeslotsForHour.map(function (timeslot) {
        const availableTimeslotFound = availableTimeslot(timeslot);
        return (
          <li key={timeslot} className={"timepicker-body-option " + getActiveClassName(availableTimeslotFound)} onClick={() => timeIntervalClicked(availableTimeslotFound)}>
            {timeslot}
          </li>
        );
      })}
    </ul>
  );
}
