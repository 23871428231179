import { ModuleType } from "@crunchit/types";
import { useCustomSelector } from "store/useStore";
import { appSettingsFactory, moduleFactory } from "./utils/factory";

// Computing values from redux state
export default function useSelector() {
  const state = useCustomSelector((state) => state.app);

  const { token, module, appSettings, isLoading } = state;

  // Computed value indicating app is initialized and content can load
  const appIsAuthenticated = token ? true : false;
  const appIsInitialized = appIsAuthenticated && !isLoading ? true : false;

  const appIsFasttrack = module?.type === ModuleType.FASTTRACK;
  const appIsEvent = module?.type === ModuleType.EVENT;

  // Fallback values, since we can safely assume the app isn't rendering without a module

  return {
    ...state,
    appIsAuthenticated,
    appIsInitialized,
    appSettings: appSettings || appSettingsFactory(),
    module: module || moduleFactory(),
    appIsFasttrack,
    appIsEvent,
  };
}
