import { formatPrice } from "@crunchit/utilities";
import { useTranslation } from "react-i18next";

import "./SimpleTotal.scss";

export default function SimpleTotal(props: { total: number }) {
  const { total } = props;
  const { t } = useTranslation();

  return (
    <div className="total simple-total">
      <span className="total-title">{t("cart:TotalPrice")}</span>
      <span className="total-amount">
        {formatPrice(total ? total : 0)} <span className="currency">{t("common:Currency")}</span>
      </span>
    </div>
  );
}
