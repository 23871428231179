import { browserIsOutdated } from "@crunchit/utilities";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";

import ErrorMessage from "components/ui/ErrorMessage";
import { useCustomDispatch } from "store/useStore";
import { useAppSelector, appActions } from "store/app";
import { menuActions } from "store/menu";
import { basketActions } from "store/basket";
import { checkoutActions } from "store/checkout";
import { logICriticalError, logError } from "utils/helpers/errors";

export default function ErrorPage() {
  const { productionUnit, error } = useAppSelector();
  const dispatch = useCustomDispatch();
  const redirect = useNavigate();

  // Checking if the user is in Internet Explorer
  const isBrowserError = useMemo(() => browserIsOutdated(), []);

  useEffect(() => {
    if (error) {
      logICriticalError(error);
    }

    try {
      dispatch(appActions.resetError());
      dispatch(basketActions.resetError());
      dispatch(menuActions.resetError());
      dispatch(checkoutActions.resetError());
    } catch (error) {
      logError(error);
    }
  }, [error, dispatch]);

  return <ErrorMessage productionUnit={productionUnit} isBrowserError={isBrowserError} handleButtonClick={() => redirect("/")} />;
}
