import { ISessionItem } from "@crunchit/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useBasketSelector } from "store/basket";
import useBasketUpdates from "hooks/useBasketUpdates";

import OrderListItem from "./OrderListItem";

import { getSummarizedOrderList } from "utils/helpers/orderlist";

import "./OrderList.scss";

interface IOrderListProps {
  products: ISessionItem[];
  showChoices?: boolean;
  isFinal?: boolean; // Indicates if we'er allowed to edit the list
}

export default function OrderList(props: IOrderListProps) {
  const { products, showChoices, isFinal } = props;

  const { t } = useTranslation();
  const { isLoading: basketIsLoading } = useBasketSelector();
  const { handleBasketChange } = useBasketUpdates();

  const summarizedItems = useMemo(() => getSummarizedOrderList(products), [products]);

  function itemCountChanged(productId: number, newCount: number) {
    if (!isFinal) {
      handleBasketChange({ newCount, productId });
    }
  }

  if (summarizedItems.length) {
    return (
      <div className={`orderlist ${isFinal ? "disabled" : ""}`}>
        {summarizedItems.map(function (product, index) {
          return <OrderListItem key={isFinal ? index : product.id} product={product} hideEditOptions={isFinal} disableEditOptions={basketIsLoading} showChoices={showChoices} itemCountChanged={itemCountChanged} />;
        })}
      </div>
    );
  }

  return (
    <div className="empty-orderlist">
      <p className="text">{t("cart:EmptyCart")}</p>
    </div>
  );
}
