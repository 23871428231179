import { IProductDto, IBasketItemDto } from "@crunchit/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import SimpleTotal from "components/basket/totals/SimpleTotal";
import useBasketUpdates from "hooks/useBasketUpdates";
import useTranslationText from "hooks/useTranslationText";
import { useBasketSelector } from "store/basket";

import "./EventProduct.scss";

interface IEventProductProps {
  product: IProductDto;
  categoryDescription?: string;
}

export default function EventProduct(props: IEventProductProps) {
  const { product, categoryDescription } = props;

  const { basket } = useBasketSelector();
  const { handleBasketChange } = useBasketUpdates();

  const { t } = useTranslation();
  const { getTranslationText } = useTranslationText();

  const currentItemCount = useMemo(() => {
    let count = 0;

    if (product && basket) {
      const findInCart = basket.items.find((prod: IBasketItemDto) => prod.productId === product.id);

      if (findInCart) {
        count = findInCart.amount;
      }
    }

    return count;
  }, [product, basket]);

  const addToBasket = () => handleBasketChange({ newCount: currentItemCount + 1, productId: product.id, product });

  const productDescription = getTranslationText(product.description);

  return (
    <div className="product event-product">
      {product.imageUrl && <img className="product-image" src={product.imageUrl} alt="" />}

      {categoryDescription && <p className="category-description">{categoryDescription}</p>}

      <div className="product-details">
        {productDescription.length > 0 && <p className="product-description">{productDescription}</p>}

        <div className="product-action">
          <SimpleTotal total={product.price} />

          <div className="product-cta-buttons">
            <button className="button button-colored gray add-button" onClick={addToBasket}>
              {t("products:AddToCartEvent")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
