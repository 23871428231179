import { useTranslation } from "react-i18next";
import Page from "components/ui/page/Page";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="page-view not-found-page">
      <div className="page-view-content inner">
        <Page title={t("pagetitles:NotFoundPage.Title")} subtitle={t("pagetitles:NotFoundPage.Subtitle")} ignoreFasttrack />
      </div>
    </div>
  );
}
