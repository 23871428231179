import { INavigationEventName } from "models/tracking";
import track from "./track";

interface INavigationEventParams {
  pathname: string;
}

const initial = (): IGTMEvent => {
  let url = document.location.protocol + "//" + document.location.hostname + document.location.pathname + document.location.search;

  return { originalLocation: url };
};

const page = (params: INavigationEventParams): IGTMEvent => {
  const { pathname } = params;

  return {
    event: "virtualPageView",
    virtualPath: pathname,
  };
};

const navigationEvent = (eventName: INavigationEventName, params?: INavigationEventParams): void => {
  switch (eventName) {
    case "INITIAL_PAGE_VIEW":
      track(initial());
      break;

    case "PAGE_VIEW":
      if (params) {
        track(page(params));
      }
      break;

    default:
      break;
  }
};

export default navigationEvent;
