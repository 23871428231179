import { ISessionChoice } from "@crunchit/types";
import { useTranslation } from "react-i18next";
import { getSummarizedChoices } from "utils/helpers/orderlist";

export default function ChoiceListPrices({ modifierIds, choices }: { modifierIds: number[]; choices: ISessionChoice[] }) {
  const { t } = useTranslation();

  const getChoicesForModifierId = (modifierId: number, choices: ISessionChoice[]) => {
    return choices.filter((choice) => choice.modifierId === modifierId);
  };

  const getTotalPrice = (choiceList: ISessionChoice[]) => {
    return choiceList.reduce((totalPrice: number, currentChoice: ISessionChoice) => {
      return (totalPrice += currentChoice.amount * currentChoice.price);
    }, 0);
  };

  return (
    <>
      {modifierIds.map((modifierId) => {
        let choiceGroup = getChoicesForModifierId(modifierId, choices);
        return (
          <ul key={`${modifierId}-prices`} className="orderlist-item-choice-list choices-price">
            {getSummarizedChoices(choiceGroup).map((choice, index) => {
              return index === 0 ? (
                <li key={choice.id}>
                  {getTotalPrice(choiceGroup)} {t("common:Currency")}
                </li>
              ) : (
                <li key={choice.id} className="empty-choice-price"></li>
              );
            })}
          </ul>
        );
      })}
    </>
  );
}
