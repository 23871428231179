import { IBasketItemDto, ICategoryDto, IOrderDto, IProductDto } from "@crunchit/types";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";

import gtm from "./gtm";
import { IBasketChoiceToAdd } from "models/basket";
import { ICartEventName, ICheckoutEventName, IMenuEventName } from "models/tracking";

export default function useTracking() {
  const { productionUnit } = useAppSelector();
  const { categories } = useMenuSelector();

  const { categoryId } = useParams();

  // Ensuring we don't track page views on the initial page load
  var [isInitialPageView, setIsInitialPageView] = useState(true);

  const trackNavigationEvent = useCallback(
    (pathname: string) => {

      if (isInitialPageView) {
        // GTM
        gtm.navigationEvent("INITIAL_PAGE_VIEW");
        setIsInitialPageView(false);
      } else {
        // GTM
        gtm.navigationEvent("PAGE_VIEW", { pathname });
      }
    },
    [isInitialPageView]
  );

  const trackMenuEvent = useCallback(
    (eventName: IMenuEventName, data: { products: IProductDto[]; categoryName?: string }): void => {
      const { products, categoryName } = data;

      let category;
      if (categoryId) {
        category = categories.find((c: ICategoryDto) => c.id === Number(categoryId));
      }

      // GTM
      gtm.menuEvent(eventName, { products, categoryName: categoryName || category?.name["da-DK"] || "", productionUnitName: productionUnit?.name || "" });
    },
    [productionUnit, categories, categoryId]
  );

  const trackCartEvent = useCallback((eventName: ICartEventName, data: { product: IProductDto | IBasketItemDto; choices?: IBasketChoiceToAdd[] }) => {
    const { product, choices } = data;

    // GTM
    gtm.cartEvent(eventName, { product, choices });
  }, []);

  const trackCheckoutEvent = useCallback((eventName: ICheckoutEventName, data: { basketProducts: IBasketItemDto[] }) => {
    const { basketProducts } = data;

    // GTM
    gtm.checkoutEvent(eventName, { basketItems: basketProducts });
  }, []);

  const trackPurchaseEvent = useCallback((order: IOrderDto) => {
    // GTM
    gtm.checkoutEvent("PURCHASE", { basketItems: order.products, order });
  }, []);

  return {
    trackNavigationEvent,
    trackMenuEvent,
    trackCartEvent,
    trackCheckoutEvent,
    trackPurchaseEvent,
  };
}
