import { IAvailableTimeDto } from "@crunchit/types";
import { useState, useCallback, useMemo } from "react";

import { useCheckoutSelector } from "store/checkout";
import withClickOutside from "hoc/withClickOutside";
import Timepicker from "./Timepicker";

interface ITimepickerProps {
  disregardAvailability: boolean;
  availableTimes: IAvailableTimeDto[];
  errorInDeliveryTime: boolean;
  handleTimeChange: (isDelivery: boolean, chosenTime?: string) => void;
}

export default function TimepickerContainer(props: ITimepickerProps) {
  const { disregardAvailability, availableTimes, errorInDeliveryTime } = props;
  const { handleTimeChange } = props;

  const { checkoutSession } = useCheckoutSelector();
  const { isDelivery } = checkoutSession;

  let [isOpen, setIsOpen] = useState(false);

  const handleTimeSelection = useCallback(
    async (updatedTimeslot: string) => {
      setIsOpen(false);
      handleTimeChange(isDelivery, updatedTimeslot);
    },
    [isDelivery, handleTimeChange]
  );

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  let TimepickerComponent = useMemo(() => withClickOutside(Timepicker, { clickHandler: handleClickOutside }), [handleClickOutside]);

  return <TimepickerComponent isOpen={isOpen} availableTimes={disregardAvailability ? availableTimes : availableTimes.filter((a) => a.isAvailable)} disregardAvailability={disregardAvailability} showDeliveryTimeError={errorInDeliveryTime} toggleIsOpen={() => setIsOpen(!isOpen)} handleTimeSelection={handleTimeSelection} />;
}
