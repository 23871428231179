import { ICheckoutGuestInformationDto } from "@crunchit/types";
import moment from "moment";
import { clientIds } from "utils/constants/survey";

export const getCompletedOrderSurveyData = (productionUnitId: number, guestInformation: ICheckoutGuestInformationDto) => {
  const clientId = clientIds[productionUnitId];

  if (!clientId) {
    throw new Error(`No client id found for production unit id ${productionUnitId}`);
  }

  const names = guestInformation.name.split(" ");
  const firstName = names[0];
  const lastName = names[1];

  const formData = new FormData();

  formData.append("shopId", clientId);
  formData.append("customerType", "takeaway");

  formData.append("firstName", firstName || "");
  formData.append("lastName", lastName || "");
  formData.append("email", guestInformation.email || "");
  formData.append("mobilenumber", guestInformation.phone || "");

  const now = moment().format("yyyy-MM-dd HH:mm:ss");

  formData.append("visitDate", now);
  formData.append("sendDate", now);

  return formData;
};
