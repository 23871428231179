import { get } from "@crunchit/http-client";
import { IDeliveryInstanceSettingsDto } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_DELIVERY_URI || `delivery.${getEnvironment()}crunchy.ninja/api`;

const DeliveryService = {
  getDeliveryInstanceSettings: async (deliveryInstanceId: string) => {
    return get<IDeliveryInstanceSettingsDto>(`${api}/instancesetting/${deliveryInstanceId}`, getToken());
  },
};

export default DeliveryService;
