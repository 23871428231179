const getCheckoutSettings = (isFasttrack = false) => {
  const defaultSettings = {
    terms: "https://bones.dk/handelsbetingelser",
    requiredGuestInformation: {
      name: true,
      phone: true,
      email: true,
    },
    enforceCapacity: true, // Determines if we prevent the user from choosing a timeslot without capacity
  };

  if (isFasttrack) {
    return {
      ...defaultSettings,
      requiredGuestInformation: {
        name: false,
        phone: false,
        email: false,
      },
      enforceCapacity: false,
    };
  }

  return defaultSettings;
};

export default getCheckoutSettings;
