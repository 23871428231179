import { IBlockedChoiceDto, IContextDto, ILabelDto, IProductDto } from "@crunchit/types";
import { useState } from "react";

import useBasketUpdates from "hooks/useBasketUpdates";
import Modifiers from "components/menu/Modifiers";

import ProductDetails from "./ProductDetails";
import "./Product.scss";

interface IProductViewProps {
  product: IProductDto;
  context: IContextDto;
  isBlockedProduct: boolean;
  blockedChoices: IBlockedChoiceDto[];
  labels?: ILabelDto[];
}

export default function Product(props: IProductViewProps) {
  const { product, context, isBlockedProduct, blockedChoices, labels } = props;

  const { handleBasketChange } = useBasketUpdates();
  let [isExpanded, setIsExpanded] = useState(false);

  const modifierToggleButtonEnabled = product.modifiers && product.modifiers.length > 0;

  function countChanged(newCount: number) {
    handleBasketChange({ newCount, productId: product.id, product });
  }

  function toggleModifiers() {
    if (modifierToggleButtonEnabled) {
      // Scrolling down when modifier dropdown is opened
      if (!isExpanded) {
        let elem = document.getElementById(product.id.toString());
        window.setTimeout(function () {
          if (elem) {
            let elementTop = elem.offsetTop;
            window.scrollTo({ top: elementTop - 80, behavior: "smooth" }); // Header on mobile is 80px
          }
        }, 100);
      }

      setIsExpanded(!isExpanded);
    }
  }

  return (
    <article className={isBlockedProduct ? "product blocked" : "product"}>
      <section className="product-content">
        {product.imageUrl && <div className="product-image" onClick={toggleModifiers} style={{ backgroundImage: "url(" + product.imageUrl + ")" }}></div>}
        <ProductDetails product={product} isExpanded={isExpanded} labels={labels} countChanged={countChanged} toggleModifiers={toggleModifiers} />
      </section>

      {isExpanded && <Modifiers product={product} context={context} blockedChoices={blockedChoices} dismiss={toggleModifiers} />}
    </article>
  );
}
