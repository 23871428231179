const api = process.env.REACT_APP_SURVEY_URI || "www.results.dk/rest/retailrelation/bonesupload";
const credentials = window.btoa("NEPIFI :1kofBiRR");

const SurveyService = {
  customerCompletedOrder: async (payload: FormData) => {
    const headers = new Headers();

    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("Authorization", `Basic ${credentials}`);

    fetch(`https://${api}`, {
      method: "POST",
      headers,
      body: payload,
    });
  },
};

export default SurveyService;
