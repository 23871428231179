import FullBasket from "./FullBasket";
import MiniBasket from "./MiniBasket";

interface IBasketProps {
  isMini?: boolean; // Basket is either in a mini state or fully expanded
  isClosing: boolean;
  handleContinue: () => void; // Click is triggered from the appropriate basket, determined by isMini
  handleBasketToggle: () => void;
}

export default function Basket(props: IBasketProps) {
  const { isMini, isClosing, handleContinue, handleBasketToggle } = props;

  if (isMini) {
    return <MiniBasket handleClick={handleBasketToggle} />;
  }

  return (
    <FullBasket
      isClosing={isClosing}
      clickHandlers={{
        basket: handleBasketToggle,
        continue: handleContinue,
      }}
    />
  );
}
