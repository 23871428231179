import { ILanguageKey, ITranslation } from "@crunchit/types";
import { useTranslation } from "react-i18next";

export default function useTranslationText() {
  const { i18n } = useTranslation();

  function getTranslationText(translations: ITranslation) {
    const language = i18n.language;
    const translation = translations[language as ILanguageKey] || translations["da-DK"] || "";
    return translation;
  }

  return { getTranslationText };
}
