import { useEffect, useMemo } from "react";
import { useParams } from "react-router";

import { IBlockedProductDto, ICategoryDto, IProductDto } from "@crunchit/types";

import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";

import Product from "components/menu/Product";
import RegularCategory from "components/menu/category/Category";
import EventCategory from "components/menu/category/EventCategory";

import useTranslationText from "hooks/useTranslationText";
import useProducts from "hooks/useProducts";
import useTracking from "tracking/useTracking";

export default function Category() {
  const { appIsInitialized, appIsFasttrack, appIsEvent } = useAppSelector();
  const { context, categories, isLoading: menuDataIsLoading } = useMenuSelector();
  const { categoryId } = useParams();

  const { getTranslationText } = useTranslationText();

  let { blockedProducts, blockedChoices, labels, products, loadWarehouse, loadLabels, loadProducts } = useProducts();

  const { trackMenuEvent } = useTracking();

  const category = useMemo(() => (categoryId ? categories.find((c: ICategoryDto) => c.id === Number(categoryId)) : undefined), [categoryId, categories]);

  const layoutClasses = useMemo(() => {
    let classes = "";

    if (products && products.length) {
      const firstProduct = products[0];

      if (firstProduct.modifiers.length === 0) {
        classes += "grid";
      }
    }

    if (appIsFasttrack) {
      classes += " fasttrack";
    }

    return classes;
  }, [products, appIsFasttrack]);

  useEffect(() => {
    if (appIsInitialized) {
      loadWarehouse();
      loadLabels();
    }
  }, [appIsInitialized, category, loadLabels, loadWarehouse]);

  useEffect(() => {
    if (category) {
      loadProducts(category);
    }
  }, [category, loadProducts]);

  useEffect(() => {
    if (products.length) {
      trackMenuEvent("CATEGORY_VIEWED", { products: products });
    }
    // eslint-disable-next-line
  }, [products]);

  if (!category) {
    return <></>;
  }

  const title = getTranslationText(category.name);
  const categoryDescription = getTranslationText(category.description);
  const subtitle = categoryDescription.length ? categoryDescription : undefined;

  const props = { title, subtitle, layoutClasses };

  return appIsEvent ? (
    <EventCategory {...props} product={products[0]} />
  ) : (
    <RegularCategory
      {...props}
      productList={
        <>
          {!menuDataIsLoading &&
            context &&
            products.map(function (product: IProductDto) {
              return <Product key={product.id} product={product} context={context} labels={labels} isBlockedProduct={blockedProducts.find((b: IBlockedProductDto) => b.productId === product.id) !== undefined} blockedChoices={blockedChoices} />;
            })}
        </>
      }
    />
  );
}
