import { useCallback, useMemo, useState } from "react";

import { useInputValidation } from "hooks/useInputValidation";
import CheckoutService from "services/CheckoutService";
import { appActions, useAppSelector } from "store/app";
import { useBasketSelector } from "store/basket";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";
import { getGiftcardPaymentAmount, getOustandingPayment } from "utils/helpers/checkout/session";
import { addCheckoutBasket, addCheckoutDiscount, addCheckoutGiftcard, addCheckoutGuestInfo, addCheckoutPayment } from "utils/helpers/checkout/update";

export function usePayment() {
  const { module, appSettings } = useAppSelector();
  const { basket, basketProducts, basketProductTotal } = useBasketSelector({ allowCartFees: true });
  const { checkout, checkoutSession, getCheckoutPayments } = useCheckoutSelector();

  const {
    isDelivery,
    chosenDate,
    chosenTimeslot,

    delivery,
    guestInformation,

    discount,
    giftcard,
  } = checkoutSession;

  const dispatch = useCustomDispatch();
  const { getInputError } = useInputValidation();

  let [termsAccepted, setTermsAccepted] = useState(false);
  let [showRestaurantOfflineError, setShowRestaurantOfflineError] = useState(false);
  let [missingFields, setMissingFields] = useState<string[]>([]);

  let giftcardInBasket = useMemo(() => basketProducts.find((p) => p.isGiftcard) !== undefined, [basketProducts]);

  const payments = useMemo(() => getCheckoutPayments(), [getCheckoutPayments]);

  const deliveryFee = (checkout.delivery && checkout.delivery.deliveryFee) || 0;
  const discountAmount = (checkoutSession.discount && checkoutSession.discount.amount) || 0;
  const giftcardPaymentAmount = getGiftcardPaymentAmount(payments);

  // The final outstanding total to charge in payment etc.
  let outstandingTotal = useMemo(() => getOustandingPayment(basketProductTotal, deliveryFee, discountAmount, giftcardPaymentAmount), [basketProductTotal, deliveryFee, discountAmount, giftcardPaymentAmount]);

  const allowedButtons = {
    onlinePayment: appSettings.onlinePayment && outstandingTotal > 0,
    mobilepay: appSettings.mobilepay && outstandingTotal > 0,
    payAtPickup: appSettings.payAtPickup && outstandingTotal > 0 && !isDelivery && !giftcardInBasket,
    placeOrder: outstandingTotal === 0,
  };

  const resetPaymentErrors = useCallback(() => {
    setShowRestaurantOfflineError(false);
    setMissingFields([]);
  }, []);

  const withFinalCheckoutData = useCallback(async () => {
    if (!chosenTimeslot) {
      throw new Error("No timeslot at payment");
    } else {
      let updatedCheckout = checkout;

      // Add the basket again
      await addCheckoutBasket(checkout.id, basket.id); // Does not return an updated checkout

      // Add the guestInfo
      updatedCheckout = await addCheckoutGuestInfo(checkout, isDelivery, guestInformation, chosenTimeslot);

      // Check discounts match our input (remove, replace or add)
      updatedCheckout = await addCheckoutDiscount(checkout, discount);

      // Check giftcards match our input (remove, replace or add)
      updatedCheckout = await addCheckoutGiftcard(checkout, { giftcard, payments });

      return updatedCheckout;
    }
  }, [checkout, basket.id, isDelivery, guestInformation, chosenTimeslot, discount, giftcard, payments]);

  const getMissingFields = useCallback(() => {
    let updatedMissingFields = [];

    const { name = "", phone = "", email = "" } = guestInformation;
    updatedMissingFields.push(getInputError("name", name, "errors:MissingFields.Name"));
    updatedMissingFields.push(getInputError("phone", phone, "errors:MissingFields.Phone"));
    updatedMissingFields.push(getInputError("email", email, "errors:MissingFields.Email"));

    if (delivery) {
      const { address, zipCode } = delivery;
      updatedMissingFields.push(getInputError("deliveryAddress", address, "errors:MissingFields.Address"));
      updatedMissingFields.push(getInputError("deliveryZipCode", zipCode, "errors:MissingFields.ZipCode"));
    }

    if (!chosenDate) {
      updatedMissingFields.push("errors:MissingFields.Date");
    }
    if (!chosenTimeslot) {
      updatedMissingFields.push("errors:MissingFields.Time");
    }

    return updatedMissingFields.filter((m) => m !== "");
  }, [chosenDate, chosenTimeslot, guestInformation, delivery, getInputError]);

  const validateCheckout = useCallback(async () => {
    resetPaymentErrors();

    dispatch(checkoutActions.setIsLoading({ isLoading: true, loadingMessageKey: "checkout:SpinnerMessage.ValidatingOrder" }));

    // Check for user input errors
    const updatedMissingFields = getMissingFields();

    if (updatedMissingFields.length > 0) {
      setMissingFields(updatedMissingFields);
      dispatch(checkoutActions.setShowValidationErrors(true));
      return false;
    }

    try {
      // Final updates for the checkout
      const updatedCheckout = await withFinalCheckoutData();

      // Order validation / Restaurant online (Simphony) check
      const orderValidationResponse = await CheckoutService.validateOrder(checkout.id, module.productionUnitId);

      // Request only returns a response if location is offline and we get an error
      if (!orderValidationResponse.isSuccess()) {
        setShowRestaurantOfflineError(true);
        throw new Error("Restaurant is offline");
      }

      dispatch(checkoutActions.setCheckout(updatedCheckout));
      return true;
    } catch (error) {
      return false;
    }
  }, [checkout.id, module.productionUnitId, resetPaymentErrors, withFinalCheckoutData, getMissingFields, dispatch]);

  const handlePayment = useCallback(
    async (paymentType: string, additionalPaymentParameter?: string) => {
      const currentUrl = window.location.href;
      const baseUrl = currentUrl.replace("/checkout", "");

      if (paymentType === "payAtPickup") {
        window.location.href = `${baseUrl}/order/${checkout.id}`;
        return;
      }

      if (paymentType === "onlinePayment") {
        const continueUrl = `${baseUrl}/order/${checkout.id}`;
        const cancelUrl = currentUrl;

        // Payment link will be fetched, which takes longer - so we show an updated loading message
        dispatch(checkoutActions.setIsLoading({ isLoading: true, loadingMessageKey: "checkout:SpinnerMessage.StartingPayment" }));

        try {
          const checkoutPayment = await addCheckoutPayment(checkout.id, module.id, cancelUrl, continueUrl, additionalPaymentParameter || "");

          if (!checkoutPayment) {
            throw new Error("No checkout payment");
          }

          window.location.href = checkoutPayment.payment.paymentLink;
        } catch (error) {
          dispatch(appActions.setError({ message: "Unable to get payment link", error }));
        }
      }
    },
    [checkout.id, module.id, dispatch]
  );

  return {
    allowedButtons,
    termsAccepted,
    showRestaurantOfflineError,
    missingFields,
    setTermsAccepted,
    validateCheckout,
    handlePayment,
  };
}
