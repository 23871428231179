export const clientIds: { [productionUnitId: number]: string } = {
  307: "1523",
  296: "1699",
  297: "2620",
  312: "8955",
  298: "5913",
  292: "8428",
  314: "3922",
  299: "4161",
  300: "9664",
  301: "9623",
  303: "9020",
  304: "4646",
  305: "6080",
  306: "9875",
  309: "3955",
  310: "5894",
  293: "2076",
  311: "9615",
  294: "4156",
  295: "7186",
  313: "4905",
  285: "9904",
  308: "9290",
  302: "4059",
};
