import { IChoiceDto, IModifierChoiceDto, IProductModifierDto, ISelectedChoice } from "@crunchit/types";
import { getTotalChoiceCount, hasReachedIncludedChoices, hasReachedMaxChoices } from "@crunchit/utilities";
import { useMemo, useCallback } from "react";

export default function useChoices(productModifier: IProductModifierDto, modifierChoices: IModifierChoiceDto[], selectedChoices?: ISelectedChoice[]) {
  // Logic across choices in a modifier
  const totalChoiceCount = selectedChoices ? getTotalChoiceCount(selectedChoices) : 0;

  const maxReached = hasReachedMaxChoices(totalChoiceCount, productModifier.maxChoices);
  const includedReached = hasReachedIncludedChoices(totalChoiceCount, productModifier.choicesIncluded, modifierChoices);

  // Single choice logic
  const getChoice = useCallback((choiceId: number, choices: IChoiceDto[]) => choices.find((c: IChoiceDto) => c.id === choiceId), []);
  const getSelectedChoice = useCallback((choiceId: number) => (selectedChoices ? selectedChoices.find((c: ISelectedChoice) => c.choiceId === choiceId) : undefined), [selectedChoices]);

  const getChoiceCount = useCallback(
    (modifierChoice: IModifierChoiceDto) => {
      const selectedChoice = getSelectedChoice(modifierChoice.choiceId);
      return selectedChoice ? selectedChoice.amount : modifierChoice.defaultChoiceAmount;
    },
    [getSelectedChoice]
  );

  const showChoicePrice = useCallback(
    (choicePrice: number, modifierChoice: IModifierChoiceDto, productQuantity: number) => {
      if (choicePrice > 0 && includedReached) {
        const selectedChoice = getSelectedChoice(modifierChoice.choiceId);

        if (selectedChoice) {
          return selectedChoice.amount >= (modifierChoice.freeAmount * productQuantity);
        }

        return modifierChoice.freeAmount === 0;
      }

      return false;
    },
    [includedReached, getSelectedChoice]
  );

  const disableChoiceIncrease = useMemo(() => maxReached, [maxReached]);

  return {
    getChoice,
    getChoiceCount,
    showChoicePrice,
    disableChoiceIncrease,
  };
}
