import { Link } from "react-router-dom";

import useHeaderNavigation from "./useHeaderNavigation";
import newYearsMenuLogo from "assets/img/new_year_logo_menu_2023.png";
import newYearsGoldLogo from "assets/img/new_year_logo_gold.png";
import Basket from "components/basket/Basket";
import SummaryBasket from "components/basket/Basket/SummaryBasket";
import { ILayoutOptions } from "models/layout";
import { useAppSelector } from "store/app";

import "./EventHeader.scss";

export default function EventHeader(props: Partial<ILayoutOptions>) {
  const { hideBasket } = props;
  const { basketIsOpen, basketIsClosing, clickHandlers } = useHeaderNavigation();

  const { productionUnit } = useAppSelector();

  return (
    <header className="header-container">
      <div>
        <Link to="/">
          <img className="menu-logo" src={newYearsMenuLogo} alt="" />
        </Link>
        
        <img className="gold-logo" src={newYearsGoldLogo} alt="" />
      </div>

      {!hideBasket && (
        <nav>
          {productionUnit?.name && <h1>{productionUnit.name}</h1>}
          <SummaryBasket handleClick={clickHandlers.basket} />
        </nav>
      )}

      {basketIsOpen && <Basket isClosing={basketIsClosing} handleContinue={clickHandlers.continue} handleBasketToggle={clickHandlers.basket} />}
    </header>
  );
}
