import { IAvailableTimeDto } from "@crunchit/types";
import Datepicker from "./Datepicker";
import Timepicker from "./Timepicker";

import "./DateTimePickers.scss";

interface IDateTimePickersProps {
  datepickerIsVisible: boolean;
  disregardAvailability: boolean;

  availableDates: Date[];
  availableTimes: IAvailableTimeDto[];

  errorInDeliveryDate: boolean;
  errorInDeliveryTime: boolean;

  handleDateChange: (date: string, isDelivery: boolean) => void;
  handleTimeChange: (isDelivery: boolean, chosenTime?: string) => void;

  datepickerIsDisabled?: boolean;
}

export default function DateTimePickers(props: IDateTimePickersProps) {
  const {
    datepickerIsVisible,
    disregardAvailability,

    availableDates,
    availableTimes,

    handleDateChange,
    handleTimeChange,

    errorInDeliveryTime,
    datepickerIsDisabled
  } = props;

  return (
    <div className="date-and-time-pickers">
      <div className="datepicker-section">{datepickerIsVisible && <Datepicker availableDates={availableDates} handleDateChange={handleDateChange} disabled={datepickerIsDisabled} />}</div>

      <div className="timepicker-section">
        <Timepicker disregardAvailability={disregardAvailability} availableTimes={availableTimes} handleTimeChange={handleTimeChange} errorInDeliveryTime={errorInDeliveryTime} />
      </div>
    </div>
  );
}
