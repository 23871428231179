import { get } from "@crunchit/http-client";
import IPlaceholderDto from "models/placeholder";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_PLACEHOLDER_URI || `placeholder.${getEnvironment()}crunchy.ninja/api`;

const PlaceholderService = {
  getPlaceholder: async (placeholderInstanceId: string, placeholderTypeId: number, languageCode: string) => {
    return get<IPlaceholderDto>(`${api}/placeholder/${placeholderTypeId}/${languageCode}?instanceId=${placeholderInstanceId}`, getToken());
  },
};

export default PlaceholderService;
