import { siteStorageName } from "@crunchit/types";
import { removeFromLocalStorage } from "@crunchit/utilities";
import moment from "moment";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CheckoutBasket from "components/checkout/CheckoutBasket";
import GuestInfo from "components/checkout/GuestInfo";
import OrderComment from "components/checkout/OrderComment";
import DeliveryMethod from "components/checkout/DeliveryMethod";
import Payment from "components/checkout/Payment";
import RestaurantPickupInfo from "components/ui/RestaurantPickupInfo";
import Page from "components/ui/page/Page";

import CheckoutService from "services/CheckoutService";
import ProductService from "services/ProductService";

import { useAppSelector, appActions } from "store/app";
import { useBasketSelector, basketThunks } from "store/basket";
import { useCheckoutSelector, checkoutActions } from "store/checkout";
import { useCustomDispatch } from "store/useStore";
import useTracking from "tracking/useTracking";
import { getCurrentCheckout, getNewYearsDate, getPreviousCheckout } from "utils/helpers/checkout/session";

import "./Checkout.scss";

export default function Checkout() {
  const { appIsInitialized, appIsFasttrack, appIsEvent, module } = useAppSelector();
  const { hasLoaded: basketHasLoaded, basket, basketProducts, bagFeeProductId } = useBasketSelector({ allowCartFees: true });
  const { checkout, checkoutInstanceSettings, checkoutSession } = useCheckoutSelector();
  const { t } = useTranslation();
  const { trackCheckoutEvent } = useTracking();

  const dispatch = useCustomDispatch();

  const [checkoutIsInitialized, setCheckoutIsInitialized] = useState(false);
  const [bagFeeIsInitialized, setBagFeeIsInitialized] = useState(false);

  const getBagFeeItemInBasket = useCallback((bagFeeProductId: number) => basketProducts.find((p) => p.productId === bagFeeProductId), [basketProducts]);

  const handleError = useCallback(
    (error: unknown) => {
      dispatch(appActions.setError({ message: "Checkout error", error }));
    },
    [dispatch]
  );

  const handleCheckoutInitializationError = useCallback(() => {
    removeFromLocalStorage(siteStorageName, "checkoutId");
    dispatch(checkoutActions.setCheckout(null));
    dispatch(checkoutActions.setIsLoading({ isLoading: false }));
  }, [dispatch]);

  const initializeCheckout = useCallback(
    async (moduleId: string, checkoutInstanceId: string, appIsEvent: boolean) => {
      dispatch(checkoutActions.setIsLoading({ isLoading: true }));
      dispatch(checkoutActions.resetCheckoutSession());

      try {
        const previousCheckout = await getPreviousCheckout();
        const checkout = await getCurrentCheckout({ moduleId, checkoutInstanceId, previousCheckout });

        if (!checkout) {
          throw new Error("Unable to get initial checkout");
        }

        dispatch(checkoutActions.setCheckout(checkout));

        if (previousCheckout && previousCheckout.guestInformation) {
          // Setting session data
          dispatch(checkoutActions.setCheckoutSessionGuestInfo(previousCheckout.guestInformation));
        }

        if (appIsEvent) {
          dispatch(checkoutActions.setChosenDate(getNewYearsDate()));
        }
      } catch (error) {
        handleCheckoutInitializationError();
        handleError(error instanceof Error ? error.message : error);
      } finally {
        dispatch(checkoutActions.setIsLoading({ isLoading: false }));
      }
    },
    [handleCheckoutInitializationError, handleError, dispatch]
  );

  const initializeBagFee = useCallback(
    async (basketId: string, bagFeeProductId: number, addBagFeeToBasket: boolean) => {
      const bagFeeBasketItem = getBagFeeItemInBasket(bagFeeProductId);

      if (bagFeeBasketItem) {
        if (addBagFeeToBasket && bagFeeBasketItem.amount > 1) {
          dispatch(basketThunks.updateBasketItem({ basketId, basketProduct: bagFeeBasketItem, updatedCount: 1 }));
        }

        if (!addBagFeeToBasket) {
          dispatch(basketThunks.removeBasketItem({ basketId, basketProductId: bagFeeBasketItem.id }));
        }
      } else if (addBagFeeToBasket) {
        dispatch(checkoutActions.setIsLoading({ isLoading: true }));
        const bagFeeProductResponse = await ProductService.getProductById(bagFeeProductId, module.productInstanceId);

        if (bagFeeProductResponse.isSuccess()) {
          const bagFeeProduct = bagFeeProductResponse.data;
          dispatch(basketThunks.addBasketItem({ product: bagFeeProduct, count: 1 }));
        }

        dispatch(checkoutActions.setIsLoading({ isLoading: false }));
      }
    },
    [dispatch, getBagFeeItemInBasket, module.productInstanceId]
  );

  useEffect(() => {
    if (appIsInitialized && !checkoutIsInitialized) {
      if (module.id && module.checkoutInstanceId) {
        initializeCheckout(module.id, module.checkoutInstanceId, appIsEvent);
        setCheckoutIsInitialized(true);
      } else {
        handleError(`No checkout instance id on module '${module.id}'`);
      }
    }
  }, [appIsInitialized, checkoutIsInitialized, module, initializeCheckout, appIsEvent, handleError]);

  useEffect(() => {
    if (basketHasLoaded && basket.id && bagFeeProductId && checkoutIsInitialized && !bagFeeIsInitialized) {
      initializeBagFee(basket.id, bagFeeProductId, !checkoutSession.bringOwnBagIsChecked);
      setBagFeeIsInitialized(true);
    }
  }, [basketHasLoaded, basket.id, bagFeeProductId, checkoutIsInitialized, bagFeeIsInitialized, checkoutSession.bringOwnBagIsChecked, initializeBagFee]);

  useEffect(() => {
    if (basket.id) {
      trackCheckoutEvent("STARTED", { basketProducts });
    }
    // eslint-disable-next-line
  }, [basket.id, trackCheckoutEvent]);

  // Initial load of instance settings
  useEffect(() => {
    const setupCheckoutInstanceSettings = async (checkoutInstanceId: string | null) => {
      if (!checkoutInstanceId) {
        handleCheckoutInitializationError();
        return;
      }

      try {
        const checkoutInstanceSettingsResponse = await CheckoutService.getCheckoutInstanceSettings(checkoutInstanceId);

        if (!checkoutInstanceSettingsResponse.isSuccess()) {
          throw new Error("Unable to get checkout instance settings");
        }

        dispatch(checkoutActions.setCheckoutInstanceSettings(checkoutInstanceSettingsResponse.data));
      } catch (error) {
        handleCheckoutInitializationError();
        handleError(error instanceof Error ? error.message : error);
      }
    };

    if (appIsInitialized && !checkoutInstanceSettings.instanceId) {
      setupCheckoutInstanceSettings(module.checkoutInstanceId);
    }
  }, [appIsInitialized, module.checkoutInstanceId, checkoutInstanceSettings.instanceId, handleCheckoutInitializationError, handleError, dispatch]);

  // Setting today's date if future ordering not allowed
  useEffect(() => {
    if (checkoutInstanceSettings.instanceId) {
      if (checkoutInstanceSettings.guestFutureOrderDates === 0) {
        dispatch(checkoutActions.setChosenDate(moment().format("DD-MM-YYYY")));
      }
    }
  }, [checkoutInstanceSettings, dispatch]);

  // A list of components, to have automatic section index
  const getCheckoutSections = () => {
    const indexOffset = 0;

    if (basketProducts.length === 0) {
      return { sections: [CheckoutBasket], indexOffset };
    }

    const sections = [RestaurantPickupInfo, CheckoutBasket, GuestInfo, OrderComment, DeliveryMethod, Payment];

    if (appIsFasttrack) {
      return { sections: sections.slice(1), indexOffset: 1 };
    }

    return { sections, indexOffset };
  };

  const checkoutSections = getCheckoutSections();

  return (
    <Page title={t("pagetitles:CheckoutPage.Title")}>
      <div className={`content-body checkout-content ${checkoutSession.showValidationErrors ? "show-validation-errors" : ""}`}>
        {checkout && (
          <div className="content-sections">
            {checkoutSections.sections.map((CheckoutSection, index) => (
              <CheckoutSection key={index} index={index + checkoutSections.indexOffset} />
            ))}
          </div>
        )}
      </div>
    </Page>
  );
}
