import { useState, useCallback, ReactNode } from "react";

import "./SectionWrapper.scss";

interface ISectionWrapperProps {
  index?: number;
  title?: string;
  isCollapsible?: boolean;
  children: ReactNode;
}

// Wrapper component for collapsible sections in checkout
export default function SectionWrapper(props: ISectionWrapperProps) {
  const { index, title, children, isCollapsible } = props;

  let [isExpanded, setIsExpanded] = useState(true);

  const indexText = index !== undefined ? `0${index} ` : "";

  let handleToggleClick = useCallback(() => {
    if (isCollapsible) {
      setIsExpanded(!isExpanded);
    }
  }, [isExpanded, isCollapsible]);

  return (
    <div className={`content-section ${isCollapsible ? 'collapsible-section' : ''} ${isExpanded ? "expanded" : "collapsed"}`}>
      {title !== undefined && (
        <div className="content-title-container" onClick={handleToggleClick}>
          <h3 className="content-title">
            <span className="number">{indexText}</span>
            {title}
          </h3>
          {isCollapsible && <div className="collapsible-icon"></div>}
        </div>
      )}

      <div className="content-body collapsible-content">{children}</div>
    </div>
  );
}
