import { ICheckoutDto, ICheckoutDiscountDto, ICheckoutGiftcardDto, ICheckoutGuestInformationDto, IPaymentDto, PaymentType } from "@crunchit/types";
import moment from "moment";

import CheckoutService from "services/CheckoutService";
import { getValidString } from "./validation";

export async function addCheckoutDeliveryAddress(checkoutId: string, moduleId: string, addressInfo: { address: string; zipCode: string }) {
  let deliveryAddressError = "";
  let { address, zipCode } = addressInfo;

  const urlFriendlyAddress = encodeURIComponent(address.toLowerCase());

  try {
    // POST new (will override any existing)
    const response = await CheckoutService.addCheckoutDeliveryAddress(checkoutId, moduleId, urlFriendlyAddress, zipCode);

    if (!response.isSuccess()) {
      deliveryAddressError = "Unable to add address to checkout";
    }
  } catch (error) {
    console.error(error);

    if (error instanceof Error) {
      deliveryAddressError = error.message;
    } else {
      deliveryAddressError = "Unable to add address to checkout";
    }
  }

  return deliveryAddressError;
}

export async function addCheckoutDeliveryDate(checkoutId: string, date: string) {
  const dateTimeString = `${moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")}T00:00:00`;
  return await CheckoutService.addCheckoutDeliveryDate(checkoutId, dateTimeString);
}

export async function addCheckoutDeliveryTime(checkoutId: string, time: string) {
  const body = time.substring(11, 16);
  return await CheckoutService.addCheckoutDeliveryTime(checkoutId, body);
}

export async function addCheckoutBasket(checkoutId: string, basketId: string) {
  const checkoutBasketResponse = await CheckoutService.addCheckoutBasket(checkoutId, basketId);

  if (!checkoutBasketResponse.isSuccess()) {
    throw new Error(`Unable to add checkout with id '${checkoutId}' to basket with id '${basketId}'`);
  }

  return true;
}

export async function addCheckoutGuestInfo(checkout: ICheckoutDto, isDelivery: boolean, guestInfo: Partial<ICheckoutGuestInformationDto>, orderReadyTime: string) {
  let updatedCheckout = Object.assign({}, checkout);

  let guestInformation: ICheckoutGuestInformationDto = {
    ...guestInfo,

    name: getValidString(guestInfo.name),

    amountOfGuests: guestInfo.amountOfGuests ? guestInfo.amountOfGuests : 0,
    amountOfKids: guestInfo.amountOfKids ? guestInfo.amountOfKids : 0,

    orderComment: getValidString(guestInfo.orderComment),
  };

  if (!isDelivery) {
    guestInformation.orderReadyTime = orderReadyTime;
  }

  const checkoutResponse = await CheckoutService.addCheckoutGuestInformation(checkout.id, guestInformation);

  if (checkoutResponse.isSuccess()) {
    updatedCheckout = checkoutResponse.data;
  } else {
    // Should always throw an error if failed
    throw new Error(`Unable to add guest information to checkout with id '${checkout.id}'`);
  }

  return updatedCheckout;
}

export async function addCheckoutDiscount(checkout: ICheckoutDto, discountData?: Partial<ICheckoutDiscountDto | null>) {
  let updatedCheckout = checkout;

  if (discountData) {
    const { discountCode = "", amount = 0 } = discountData;

    if (checkout.discounts.length) {
      // Discounts already exist on this checkout - compare to current data

      // For each discount that doesn't match the current input, delete it
      for (let i = 0; i < checkout.discounts.length; i++) {
        const existingDiscount = checkout.discounts[i];

        if (existingDiscount.discountCode !== discountCode || existingDiscount.amount !== amount) {
          const discountRemovedResponse = await CheckoutService.removeDiscount(checkout.id, existingDiscount.id || "");

          if (discountRemovedResponse.isSuccess()) {
            updatedCheckout = discountRemovedResponse.data;
          }

          if (existingDiscount.discountCode === discountCode) {
            // The discount code was already added, but the amount had changed. Post it back again
            const discountAddedResponse = await CheckoutService.addCheckoutDiscount(checkout.id, discountData);

            if (discountAddedResponse.isSuccess()) {
              updatedCheckout = discountAddedResponse.data;
            }
          }
        }
      }
    } else {
      // No discounts yet - add it if we have one entered
      if (discountCode && amount) {
        const discountResponse = await CheckoutService.addCheckoutDiscount(checkout.id, discountData);

        if (discountResponse.isSuccess()) {
          updatedCheckout = discountResponse.data;
        }
      }
    }
  }

  return updatedCheckout;
}

export async function addCheckoutGiftcard(checkout: ICheckoutDto, giftcardData?: { giftcard?: Partial<ICheckoutGiftcardDto> | null; payments?: Partial<IPaymentDto>[] | null }) {
  let updatedCheckout = checkout;

  const getGiftcard = (giftcard?: Partial<ICheckoutGiftcardDto> | null, payments?: Partial<IPaymentDto>[] | null) => {
    const giftcardCode = giftcard?.giftcardCode;
    let amount = 0;

    if (payments?.length) {
      const giftcardPayment = payments.find((p) => p.type === PaymentType.GIFTCARD);
      amount = giftcardPayment?.amount || 0;
    }

    return { giftcardCode, amount };
  };

  const { giftcardCode, amount } = getGiftcard(giftcardData?.giftcard, giftcardData?.payments);

  let giftcardWasAdded = false;

  const addNewGiftcard = async (updatedCheckout: ICheckoutDto) => {
    let updated = updatedCheckout;

    if (giftcardCode && amount) {
      giftcardWasAdded = true;

      const giftcardResponse = await CheckoutService.addCheckoutGiftcard(checkout.id, giftcardCode, amount);

      if (giftcardResponse.isSuccess()) {
        updated = giftcardResponse.data;
      }
    }
    return updated;
  };

  const removeGiftcard = async (updatedCheckout: ICheckoutDto, giftcardId: string) => {
    let updated = updatedCheckout;

    if (giftcardId) {
      const removedResponse = await CheckoutService.removeDiscount(checkout.id, giftcardId);

      if (removedResponse.isSuccess()) {
        updatedCheckout = removedResponse.data;
      }
    }

    return updated;
  };

  // Giftcards already exist on this checkout - compare to current data
  if (checkout.giftcards.length) {
    for (let i = 0; i < checkout.giftcards.length; i++) {
      const existingGiftcard = checkout.giftcards[i];

      // For each giftcard that doesn't match the current input, delete it
      if (existingGiftcard.giftcardCode !== giftcardCode) {
        updatedCheckout = await removeGiftcard(updatedCheckout, existingGiftcard.id || "");
      }

      // The giftcard code was already added, but the amount had changed. Post it back again
      if (existingGiftcard.giftcardCode === giftcardCode && existingGiftcard.amount !== amount) {
        updatedCheckout = await removeGiftcard(updatedCheckout, existingGiftcard.id || "");
        updatedCheckout = await addNewGiftcard(updatedCheckout);
      }
    }
  }

  // Add new one
  if (!giftcardWasAdded) {
    updatedCheckout = await addNewGiftcard(updatedCheckout);
  }

  return updatedCheckout;
}

export async function addCheckoutPayment(checkoutId: string, moduleId: string, cancelUrl: string, continueUrl: string, paymentMethods?: string) {
  let checkoutPayment;
  const checkoutPaymentResponse = await CheckoutService.addCheckoutPayment(checkoutId, moduleId, continueUrl, cancelUrl, paymentMethods);

  if (checkoutPaymentResponse.isSuccess()) {
    checkoutPayment = checkoutPaymentResponse.data;
  }

  return checkoutPayment;
}
