import { ReactNode } from "react";
import { useAppSelector } from "store/app";
import PageTitle from "../PageTitle";

interface IPageProps {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  ignoreFasttrack?: boolean;
}

export default function Page(props: IPageProps) {
  const { title, subtitle, children, ignoreFasttrack } = props;
  const { appIsFasttrack } = useAppSelector();

  return (
    <>
      {title && (ignoreFasttrack || !appIsFasttrack) && <PageTitle title={title} subtitle={subtitle} />}
      {children}
    </>
  );
}
