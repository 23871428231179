import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDeliveryInstanceSettingsDto, IFrontendSettingsDto, IModuleDto, IProductionUnitDto, IUserDto } from "@crunchit/types";

import AuthService from "services/AuthService";
import ModuleService from "services/ModuleService";
import ProductionUnitService from "services/ProductionUnitService";
import { RootState } from "store/store";
import { getFrontendSettings, getMaintenanceMode, setupLanguage } from "./utils/helpers";
import DeliveryService from "services/DeliveryService";
import { ICriticalError } from "models/store";

type Rejection = { rejectValue: ICriticalError };
type AuthenticateSuccess = { user: IUserDto; module: IModuleDto };
type InitializeSuccess = { module: IModuleDto; productionUnit: IProductionUnitDto | null; frontendSettings: Partial<IFrontendSettingsDto>; languages: string[]; isDownForMaintenance: boolean };

/* Async reducers */

export const authenticate = createAsyncThunk<AuthenticateSuccess, void, Rejection>("app/authenticate", async (args, { rejectWithValue }) => {
  const domain = window.location.hostname;

  try {
    const moduleResponse = await ModuleService.getModuleByDomain(domain);

    if (!moduleResponse.isSuccess()) {
      return rejectWithValue({ message: `Unable to get module by domain '${domain}'`, error: moduleResponse.errors });
    }

    const module = moduleResponse.data;
    const userResponse = await AuthService.authenticate(module.productionUnitId);

    if (!userResponse.isSuccess()) {
      return rejectWithValue({ message: `Unable to authenticate in module with production unit id '${module.productionUnitId}'`, error: userResponse.errors });
    }

    return { user: userResponse.data, module };
  } catch (error) {
    return rejectWithValue({ message: "Unable to authenticate in app", error: error instanceof Error ? error.message : error });
  }
});

export const initialize = createAsyncThunk<InitializeSuccess, void, Rejection>("app/initialize", async (args, { getState, rejectWithValue }) => {
  const state = getState() as RootState;
  const { module } = state.app;

  try {
    if (!module) {
      return rejectWithValue({ message: `No module to initialize app with` });
    }

    const moduleResponse = await ModuleService.getModuleById(module.id);

    if (!moduleResponse.isSuccess()) {
      return rejectWithValue({ message: `Unable to get module by id '${module.id}'`, error: moduleResponse.errors });
    }

    const trustedModule = moduleResponse.data;

    if (!trustedModule.isVisible) {
      return rejectWithValue({ message: `Module with id '${trustedModule.id}' is not visible` });
    }

    const [productionUnitResponse, frontendSettings, languages, isDownForMaintenance] = await Promise.all([ProductionUnitService.getProductionUnitById(module.productionUnitId), getFrontendSettings(module.id), setupLanguage(), getMaintenanceMode()]);
    const productionUnit = productionUnitResponse.isSuccess() ? productionUnitResponse.data : null;

    return { module: trustedModule, productionUnit, frontendSettings, languages, isDownForMaintenance };
  } catch (error) {
    return rejectWithValue({ message: "Unable to initialize app", error: error instanceof Error ? error.message : error });
  }
});

export const loadDeliveryInstanceSettings = createAsyncThunk<IDeliveryInstanceSettingsDto, void, Rejection>("app/loadDeliveryInstanceSettings", async (args, { rejectWithValue, getState }) => {
  const state = getState() as RootState;
  const { module } = state.app;

  try {
    if (!module || !module.deliveryInstanceId) {
      return rejectWithValue({ message: "Module has no delivery instance" });
    }

    const response = await DeliveryService.getDeliveryInstanceSettings(module.deliveryInstanceId);

    if (!response.isSuccess()) {
      return rejectWithValue({ message: `Unable to get delivery settings from instance '${module.deliveryInstanceId}'`, error: response.errors });
    }

    return response.data;
  } catch (error) {
    return rejectWithValue({ message: "Unable to load delivery instance settings", error: error instanceof Error ? error.message : error });
  }
});

const thunks = {
  authenticate,
  initialize,
  loadDeliveryInstanceSettings,
};

export default thunks;
