import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/app";

import "./InlineRestaurantPickupInfo.scss";

export default function InlineRestaurantPickupInfo() {
  const { productionUnit } = useAppSelector();
  const { t } = useTranslation();

  const streetAndCity = useMemo(() => {
    if (productionUnit && productionUnit.address) {
      return productionUnit.address.split(",");
    }
  }, [productionUnit]);

  if (!productionUnit) {
    return <></>;
  }

  return (
    <div className="inline-restaurant-pickup-info">
      <h1>{t("checkout:Pickup.Title")}:</h1>

      <p className="location-name">{productionUnit.name}</p>

      {streetAndCity && (
        <p className="location-address">
          {streetAndCity[0]}, {productionUnit.zipCode} {streetAndCity[1]}
        </p>
      )}

      {productionUnit.phone && (
        <p className="location-phone">
          {t("checkout:Pickup.TelephoneNumber")} {productionUnit.phone}
        </p>
      )}
    </div>
  );
}
