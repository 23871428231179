import { ICheckoutSectionProps } from "@crunchit/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAppSelector } from "store/app";
import { useCheckoutSelector } from "store/checkout";
import { useBasketSelector } from "store/basket";

import SectionWrapper from "components/checkout/SectionWrapper";
import OrderList from "components/basket/OrderList/OrderList";
import Empty from "./Empty";
import ItemizedTotal from "components/basket/totals/ItemizedTotal";

import Discount from "./Discount";
import Giftcard from "./Giftcard";

import "./CheckoutBasket.scss";

export default function CheckoutBasket(props: ICheckoutSectionProps) {
  const { index } = props;

  const { module, appIsEvent } = useAppSelector();
  const { basketProducts, basketProductTotal } = useBasketSelector({ allowCartFees: true });
  const { checkoutSession, checkout, checkoutInstanceSettings, getCheckoutPayments } = useCheckoutSelector();

  const { isDiscountEnabled, isGiftcardEnabled } = checkoutInstanceSettings;
  const { discount } = checkoutSession;

  const { t } = useTranslation();
  const redirect = useNavigate();

  let giftcardInBasket = useMemo(() => basketProducts.find((p) => p.isGiftcard) !== undefined, [basketProducts]);

  const deliveryFee = (checkout.delivery && checkout.delivery.deliveryFee) || 0;
  const discountAmount = (discount && discount.amount) || 0;

  const payments = useMemo(() => getCheckoutPayments(), [getCheckoutPayments]);

  function handleContinueShoppingClick() {
    redirect("/");
  }

  return basketProducts.length === 0 ? (
    <Empty handleButtonClick={handleContinueShoppingClick} />
  ) : (
    <SectionWrapper index={index} title={t("checkout:Cart.Title")}>
      <OrderList products={basketProducts} showChoices={true} />

      <ItemizedTotal orderTotal={basketProductTotal - discountAmount + deliveryFee} isDiscountEnabled={isDiscountEnabled} discountAmount={discountAmount} deliveryFee={deliveryFee} payments={payments} />

      {isDiscountEnabled && module.discountInstanceId && <Discount discountInstanceId={module.discountInstanceId} />}

      {isGiftcardEnabled && module.giftcardInstanceId && !giftcardInBasket && <Giftcard giftcardInstanceId={module.giftcardInstanceId} />}

      <div className="footer">
        <button className={`button button-colored ${appIsEvent ? "gray" : "red"}`} onClick={handleContinueShoppingClick}>
          {t("common:Navigation.ContinueShopping")}
        </button>
      </div>
    </SectionWrapper>
  );
}
