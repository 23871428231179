import { IChoiceDto, IModifierDto, IProductModifierDto, ISelectedChoice } from "@crunchit/types";
import { formatPrice, getSingleChoicePrice, getTotalChoiceCount } from "@crunchit/utilities";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Choices from "components/menu/Choices";
import useTranslationText from "hooks/useTranslationText";
import { getTotalChoicePriceForModifier } from "utils/helpers/menu";

interface IModifierViewProps {
  modifierIndex: number;

  modifier: IModifierDto;
  productModifier: IProductModifierDto;

  choices: IChoiceDto[];
  productQuantity: number;
  selectedChoices?: ISelectedChoice[];

  handleChoiceCountUpdate: (choiceId: number, modifier: IModifierDto, newCount: number) => void;

  dismiss: () => void;
  expand: () => void;
}

export default function Modifier(props: IModifierViewProps) {
  const { modifier, modifierIndex, productModifier, choices, productQuantity, selectedChoices } = props;
  const { handleChoiceCountUpdate } = props;

  const { t } = useTranslation();
  const { getTranslationText } = useTranslationText();

  const totalChoiceCount = selectedChoices ? getTotalChoiceCount(selectedChoices) : 0;
  const translatedModifierName = getTranslationText(modifier.name);

  const defaultModifierChoicePrice = useMemo(() => {
    if (modifier.choices.length) {
      const defaultModifierChoice = modifier.choices[0];
      const defaultChoice = choices.find((c: IChoiceDto) => c.id === defaultModifierChoice.choiceId);

      if (defaultChoice) {
        const defaultChoicePrice = getSingleChoicePrice(defaultChoice.defaultPrice, defaultModifierChoice.overwritePrice);
        return formatPrice(defaultChoicePrice);
      }
    }
  }, [choices, modifier.choices]);

  const totalModifierChoicePrice = useMemo(() => {
    if (selectedChoices && totalChoiceCount > 0) {
      return getTotalChoicePriceForModifier(modifier, productModifier.choicesIncluded, choices, selectedChoices, productQuantity);
    }

    return 0;
  }, [modifier, productModifier, choices, selectedChoices, totalChoiceCount, productQuantity]); // including totalChoiceCount to force update of price

  const modifierTitle = useMemo(() => {
    if (!modifier.choices.some((c) => c.freeAmount) && productModifier.choicesIncluded > 0) {
      const choicesIncludedCount = productQuantity * productModifier.choicesIncluded;
      return `${t("products:ChooseUpTo")} ${choicesIncludedCount} ${translatedModifierName}`;
    } else {
      return `${t("products:Choose")} ${translatedModifierName}`;
    }
  }, [translatedModifierName, t, productModifier.choicesIncluded, productQuantity, modifier.choices]);

  return (
    <article className="modifier collapsible-section expanded">
      <h1 className="modifier-title" id={modifier.id.toString()}>
        <span className="modifier-counter">0{modifierIndex}</span> {modifierTitle}
      </h1>

      <Choices choices={choices} modifierChoices={modifier.choices} selectedChoices={selectedChoices} productModifier={productModifier} handleChoiceCountUpdate={(choiceId: number, newCount: number) => handleChoiceCountUpdate(choiceId, modifier, newCount)} />

      {defaultModifierChoicePrice && (
        <footer>
          <p className="extra-choice-text">{t("products:ExtraChoice", { name: translatedModifierName.toLowerCase(), price: defaultModifierChoicePrice })}</p>
          <p className="extra-choice-price">{`${totalModifierChoicePrice}`}</p>
        </footer>
      )}
    </article>
  );
}
